export const getStatusColor = status => {
  switch (status.toLowerCase()) {
    case 'completed':
      return { bg: 'success.main', text: 'white' };
    case 'canceled':
      return { bg: 'error.main', text: 'white' };
    case 'no_show':
      return { bg: 'error.main', text: 'white' };
    case 'upcoming':
      return { bg: 'info.main', text: 'white' };
    default:
      return { bg: 'grey.500', text: 'white' };
  }
};

export const getStatusTextColor = status => {
  switch (status) {
    case 'completed':
      return '#0e9f6e';
    case 'canceled':
      return '#3182ce';
    case 'no_show':
      return '#d69e2e';
    case 'Cancelled':
      return '#e53e3e';
    case 'upcoming':
      return '#dd6b20';
    default:
      return '#3182ce';
  }
};

export const getMoodEmoji = mood => {
  switch (mood) {
    case 1:
      return '😢'; // Very sad
    case 2:
      return '😔'; // Sad
    case 3:
      return '😐'; // Neutral
    case 4:
      return '🙂'; // Happy
    case 5:
      return '😄'; // Very happy
    default:
      return '❓'; // Unknown
  }
};

export const getSleepEmoji = sleep => {
  switch (sleep) {
    case 1:
      return '😴 Poor';
    case 2:
      return '😴 Fair';
    case 3:
      return '😴 Average';
    case 4:
      return '😴 Good';
    case 5:
      return '😴 Excellent';
    default:
      return '❓ Unknown';
  }
};

export const getAppetiteEmoji = appetite => {
  switch (appetite) {
    case 1:
      return '🍽️ Poor';
    case 2:
      return '🍽️ Fair';
    case 3:
      return '🍽️ Average';
    case 4:
      return '🍽️ Good';
    case 5:
      return '🍽️ Excellent';
    default:
      return '❓ Unknown';
  }
};

export const formatDate = dateString => {
  const options = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };
  return new Date(dateString).toLocaleDateString('en-US', options);
};
