import { httpConstants } from "../constants";
import { httpService } from "../utility/httpService";
import { getHeaders } from "./baseFunctions";



const patientModule = {
    getPatientByEntityService,
    
    
};

export default patientModule;



async function getPatientByEntityService(requestData) {
    let url = `${process.env.REACT_APP_API_URI}/api/user/get-patient-by-entity/${requestData.entityId}`;

    return httpService(httpConstants.METHOD_TYPE.GET, getHeaders(), requestData, url)
        .then(response => {
            if (!response.success || response.responseCode !== 200 || !response.responseData) {
                return Promise.reject(response);
            }
            return Promise.resolve(response.responseData);
        })
        .catch(err => {
            return Promise.reject(err);
        });
}



