import { useState } from 'react';
import useStyles from '../doctorStyles';
import { Typography, Box, Paper, Grid, Chip, Button, TextField, Tabs, Tab } from '@mui/material';
import { Assessment as AssessmentIcon, Save as SaveIcon, MoreHoriz as MoreHorizIcon, ListAlt as ListAltIcon } from '@mui/icons-material';
import { Moon, Utensils } from 'lucide-react';
import { LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Line, ResponsiveContainer } from 'recharts';
import { formatDate } from '../utils/helpers';
import { timeOptions } from '../data/mockData';
import moment from 'moment/moment';

const JournalTab = ({ journalData, timeFilter, setTimeFilter }) => {
  const classes = useStyles();
  const [journalTab, setJournalTab] = useState(0);
  const [journalText, setJournalText] = useState('');

  const handleJournalChange = e => {
    setJournalText(e.target.value);
  };

  const handleSaveJournal = () => {
    // Here you would typically save the journal to your backend
    alert('This is a test feature. Coming soon');
    setJournalText('');
  };

  // Calculate average metrics for the journal
  const calculateAverages = () => {
    if (journalData.length === 0) return { mood: 0, sleep: 0, appetite: 0 };

    const totals = journalData.reduce(
      (acc, entry) => {
        return {
          mood: acc.mood + entry.mood,
          sleep: acc.sleep + entry.sleep,
          appetite: acc.appetite + entry.appetite,
        };
      },
      { mood: 0, sleep: 0, appetite: 0 }
    );

    return {
      mood: (totals.mood / journalData.length).toFixed(1),
      sleep: (totals.sleep / journalData.length).toFixed(1),
      appetite: (totals.appetite / journalData.length).toFixed(1),
    };
  };

  const averages = calculateAverages();

  const moodRenderData = [
    {
      name: 'mood',
      label: 'Mood',
      color: '#ecc94b',
      icon: (
        <span role="img" aria-label="mood" style={{ marginRight: '8px' }}>
          😊
        </span>
      ),
    },
    { name: 'sleep', color: '#48bb78', label: 'Sleep', icon: <Moon style={{ marginRight: '8px', width: '18px', height: '18px' }} /> },
    {
      name: 'appetite',
      color: '#4299e1',
      label: 'Appetite',
      icon: <Utensils style={{ marginRight: '8px', width: '18px', height: '18px' }} />,
    },
  ];

  const renderJournalContent = () => {
    switch (journalTab) {
      case 0: // Overview
        return (
          <>
            <div className={classes.journalMetrics}>
              <div className={classes.metricCard}>
                <span role="img" aria-label="mood">
                  😊
                </span>
                <div className={classes.metricValue}>{averages.mood}</div>
                <div className={classes.metricLabel}>Avg. Mood</div>
              </div>
              <div className={classes.metricCard}>
                <Moon size={24} />
                <div className={classes.metricValue}>{averages.sleep}</div>
                <div className={classes.metricLabel}>Avg. Sleep</div>
              </div>
              <div className={classes.metricCard}>
                <Utensils size={24} />
                <div className={classes.metricValue}>{averages.appetite}</div>
                <div className={classes.metricLabel}>Avg. Appetite</div>
              </div>
            </div>

            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Paper style={{ padding: '20px' }}>
                  <Typography className={classes.progressTitle}>
                    <AssessmentIcon className={classes.progressIcon} />
                    Patient Progress
                  </Typography>
                  {moodRenderData.map(renderItem => (
                    <>
                      <Typography variant="subtitle2" style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                        {renderItem.icon}
                        {renderItem.label}
                      </Typography>
                      <div className={classes.chartContainer}>
                        <ResponsiveContainer width="100%" height="100%">
                          <LineChart data={journalData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                            <CartesianGrid strokeDasharray="3 3" vertical={false} />
                            <XAxis
                              dataKey="date"
                              tickFormatter={(date, index) => {
                                const options = { month: 'short', day: 'numeric' };
                                let showEveryNthItem = 1;
                                if (journalData.length <= 7) showEveryNthItem = 1;
                                else if (journalData.length >= 7 && journalData.length <= 15) showEveryNthItem = 1;
                                else if (journalData.length > 7 && journalData.length <= 15) showEveryNthItem = 2;
                                else if (journalData.length > 15 && journalData.length <= 30) showEveryNthItem = 3;
                                else if (journalData.length > 30 && journalData.length <= 60) showEveryNthItem = 10;
                                else if (journalData.length > 60 && journalData.length <= 100) showEveryNthItem = 12;
                                return index % showEveryNthItem === 0 ? moment(date).format('D MMM') : '';
                              }}
                            />
                            <YAxis
                              ticks={[1, 2, 3]}
                              tickFormatter={value => {
                                if (value === 1) return 'Poor';
                                if (value === 2) return 'Fair';
                                if (value === 3) return 'Good';
                                return '';
                              }}
                            />
                            <Tooltip labelFormatter={label => moment(label).format('MMM D, YYYY')} />
                            <Line type="monotone" dataKey={renderItem.name} stroke={renderItem.color} activeDot={{ r: 8 }} dot={{ r: 4 }} />
                          </LineChart>
                        </ResponsiveContainer>
                      </div>
                    </>
                  ))}
                </Paper>
              </Grid>

              <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                <Paper style={{ padding: '20px', flexGrow: 1 }}>
                  <Typography className={classes.journalTitle}>
                    <ListAltIcon className={classes.journalIcon} />
                    Recent Journal Entries
                  </Typography>

                  <div style={{ maxHeight: '800px', height: '100%', overflowY: 'auto' }}>
                    {journalData.slice(0, 6).map(entry => (
                      <div key={entry._id} className={classes.journalEntryContainer}>
                        <Typography className={classes.journalEntryDate}>{formatDate(entry.date)}</Typography>
                        <Box display="flex" gap={1} mb={1}>
                          <div>{entry.journal}</div>
                        </Box>
                        <Typography className={classes.journalEntryContent}>{entry.content}</Typography>
                      </div>
                    ))}
                    <Box display="flex" justifyContent="center" mt={2}>
                      <Button variant="outlined" color="primary" onClick={() => setJournalTab(1)}>
                        View All Entries
                      </Button>
                    </Box>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </>
        );
      case 1: // All Entries
        return (
          <Paper style={{ padding: '20px' }}>
            <div className={classes.timeFilterContainer}>
              <Typography variant="h6" gutterBottom>
                All Journal Entries
              </Typography>
            </div>

            <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
              {journalData.map(entry => (
                <div key={entry._id} className={classes.journalEntryContainer}>
                  <Typography className={classes.journalEntryDate}>{formatDate(entry.date)}</Typography>
                  <Box display="flex" gap={1} mb={1}>
                    <div>{entry.journal}</div>
                  </Box>
                  <Typography className={classes.journalEntryContent}>{entry.content}</Typography>
                </div>
              ))}
            </div>
            <Box display="flex" justifyContent="center" mt={2}>
              <Button variant="outlined" color="primary" onClick={() => setJournalTab(0)}>
                Back to Overview
              </Button>
            </Box>
          </Paper>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div className={classes.journalHeader}>
        <div style={{ display: 'flex' }}>
          <Typography variant="h5" style={{ fontWeight: '500', marginRight: 20 }}>
            Patient Journal
          </Typography>
          <select value={timeFilter} onChange={e => setTimeFilter(e.target.value)} style={{ width: '180px', padding: 2 }}>
            {timeOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <Tabs value={journalTab} onChange={(e, newValue) => setJournalTab(newValue)} className={classes.journalTabs}>
          <Tab label="Overview" />
          <Tab label="All Entries" />
        </Tabs>
      </div>

      {renderJournalContent()}
    </div>
  );
};

export default JournalTab;
