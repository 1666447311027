import React, { useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Box,
  TableSortLabel,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { EntityService } from '../../../../services';
import CouponList from './CouponList';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationDialog from '../../../../common/confirmationDialog';
import PatientPortal from '../../../CorporateConsole/CorporateDashboard/PatientPortal.js/patientPortal';
import CommonDetails from '../CommonDetails/CommonDocAndPatientDetails';
import { showErrorMessage, showSuccessMessage } from '../../../../managers/utility';
import { useAuth } from '../../../../AuthContext';

export default function DetailsView({ refetch,getDoctorList, rowData, setIsTableVisible, apiData, doctorList , patientData, selectedRow }) {
  console.log(apiData, rowData);
  const [couponList, setCouponList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [sortedData, setSortedData] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [orgName, setOrgName] = useState('');
  const [entityId, setEntityId] = useState(
    selectedRow?._id
  );
  const [affilatedEntityName, setAffilatedEntityName] = useState(
    selectedRow?.name
  )
  const [organizationData, setOrganizationData] = useState([]);
  const { userDetails } = useAuth();


  useEffect(() => {
    getCouponbyEntity(rowData?._id);
  }, [rowData?._id]); // Include rowData._id in the dependency array

  useEffect(() => {
    getOrganizationDataByHospital()
    setSortedData(apiData);
  }, [apiData]);

  const getCouponbyEntity = async id => {
    const payload = {
      entityId: id || '',
    };
    try {
      const response = await EntityService.getCouponbyEntity(payload);
      setCouponList(response);
    } catch (error) {
      console.error('Error fetching coupons:', error);
    }
  };

  const handleSearchChange = event => {
    setSearchText(event.target.value);
    filterData(event.target.value);
  };

  const filterData = text => {
    const filteredData = apiData.filter(row => row.name.toLowerCase().includes(text.toLowerCase()));
    setSortedData(filteredData);
  };

  const handleRequestSort = property => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    const sorted = [...sortedData].sort((a, b) => {
      const propA = a[property] ?? '';
      const propB = b[property] ?? '';
      return isAsc ? propA.localeCompare(propB) : propB.localeCompare(propA);
    });
    setSortedData(sorted);
  };

  const handleDeleteClick = async () => {
    const data = await EntityService.deleteEntity({ entityId: rowData._id });
    if (data.message === 'Entity Deleted') {
      setIsTableVisible(true);
      refetch();
    }
    console.log(data);
  };

  const handleAddOrganization = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

 

  const handleSaveOrganization = async () => {
    const payload = {
      name: orgName, entityId, affilatedEntityName
    };
    try {
      const response = await EntityService.addEntityOrganization(payload);
      if (response) {
        showSuccessMessage('Organization Added Successfully')
        setOpenModal(false);
      }
    } catch (error) {
      console.error('Error fetching coupons:', error);
      showErrorMessage("Error Adding Organization")
    }
  };



  const getOrganizationDataByHospital = async () => {
    if (selectedRow && selectedRow?._id) {
      const organization = await EntityService.getOrganizationByHospital({ entityId: selectedRow?._id });
      console.log(organization, 'patients');
      setOrganizationData(organization);
    } else {
      showErrorMessage('Error in finding your Organization');
    }
  };

  console.log('organizationData', organizationData)

  return (
    <>
    
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 2 }}>
        <TextField label="Search" variant="outlined" size="small" value={searchText} onChange={handleSearchChange} />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
        <Button variant="contained" color="primary" onClick={handleAddOrganization}>
          Add Organization
        </Button>
      </Box>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>Entity Name : {rowData.name}</span>
          <span> Paid Licenses Remaining : {rowData.numberOfLicense}</span>
          <span> Collection Type : {rowData.collectionType}</span>
        </div>
        
        <div>
          <ConfirmationDialog actionOnConfirm={handleDeleteClick}>
            <Button aria-label="Delete" color="secondary" startIcon={<DeleteIcon />}>
              Delete
            </Button>
          </ConfirmationDialog>
        </div>
      </div>
    
      <CommonDetails getDoctorList={getDoctorList} patientData={patientData} doctorList={doctorList} organizationData={organizationData} />

      {/* {couponList && <CouponList couponList={couponList} />} */}

      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Add Organization</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Organization Name"
            variant="outlined"
            margin="dense"
            value={orgName}
            onChange={e => setOrgName(e.target.value)}
          />
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="secondary">Cancel</Button>
          <Button onClick={handleSaveOrganization} color="primary" variant="contained">Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
