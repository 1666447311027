import React, { useState, useEffect } from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  TextField
} from "@mui/material";
import DetailsView from "./DetailsView";
import { DoctorService, EntityService, PatientService } from "../../../../services";
import AddPlansForCorporateModal from "./AddPlansForCorporateModal";
import { showErrorMessage } from "../../../../managers/utility";

const ENTITY_TYPES = {
  PSYCHOLOGIST: "Mental Health Professional",
  CORPORATE: "Corporate",
  HOSPITAL: "Medical Facility",
  INTEGRATION: "Integration",
};

export const ENTITY_TYPES_ENUM = {
  PSYCHOLOGIST: "PSYCHOLOGIST",
  CORPORATE: "CORPORATE",
  HOSPITAL: "HOSPITAL",
  INTEGRATION: "Integration",
};

export const COUPON_SYSTEM_ENTITIES = [
  ENTITY_TYPES_ENUM.HOSPITAL,
  ENTITY_TYPES_ENUM.PSYCHOLOGIST,
];

export const CORPORATE_SYSTEM_ENTITES = [ENTITY_TYPES_ENUM.CORPORATE];

const CorporateTable = ({
  data,
  refetch,
  showInput,
  editableLicense,
  inputValue,
  setInputValue,
  handleCheckClick,
  handleButtonClick,
  handleOpenCouponModal,
  openAddModal,
  getCorporateData
}) => {
  const [isTableVisible, setIsTableVisible] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [apiData, setApiData] = useState(null);
  const [addPlanData, setAddPlanData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filteredData, setFilteredData] = useState(data); // State to hold filtered data
  const [patientData, setPatientData] = useState([])
  const [doctorList, setDoctorList] = useState([])

  console.log('selectedRow', selectedRow)
  useEffect(() => {
    getDoctorList()
    getPatientList()
    setFilteredData(data); // Initialize filtered data with original data
  }, [data]);

   
const getDoctorList = async () => {
  if(selectedRow && selectedRow?._id){
    const doctors = await DoctorService.getDoctorsByEntityService({ entityId: selectedRow?._id });
    console.log(doctors,"doctors by hospitals")
    setDoctorList(doctors);
  }else {
    // showErrorMessage("Error in finding your hospital")
  }
};

const getPatientList = async () => {
  if(selectedRow && selectedRow?._id){
    const patients = await PatientService.getPatientByEntityService({ entityId: selectedRow?._id });
    console.log(patients,"doctors by hospitals")
    setPatientData(patients);
  }else {
    // showErrorMessage("Error in finding your hospital")
  }
};


  const handleRowClick = (row) => {
    if (row.type !== "CORPORATE") {
      getCorporateData(row);
      getEntityUsers(row);
      setIsTableVisible(false);
      setSelectedRow(row);
    }
  };

  const handleBackButton = () => {
    setIsTableVisible(true);
  };

  const getEntityUsers = async (row) => {
    try {
      const response = await EntityService.getEntityEmployees(row._id || "");
      setApiData(response);
    } catch (error) {
      console.error("Error fetching corporate data:", error);
    }
  };

  const handleSearchChange = (event) => {
    const searchText = event.target.value.toLowerCase();
    const filteredData = data.filter(row =>
      row.name.toLowerCase().includes(searchText) ||
      row.email.toLowerCase().includes(searchText)
    );
    setFilteredData(filteredData);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenModal = (e, row) => {
    e.stopPropagation();
    setAddPlanData(row);
    setIsModalOpen(true);
  };

  return (
    <div>
      {isTableVisible ? (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 1 }}>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              onChange={handleSearchChange}
            />
          </Box>
          <Button style={{ marginBottom: 15 }} variant="contained" color="primary" onClick={openAddModal}>
            Add
          </Button>
          <TableContainer component={Paper}>
            {filteredData.length > 0 && (
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold', fontSize: "20px" }} >Name</TableCell>
                    <TableCell style={{ fontWeight: 'bold', fontSize: "20px" }} align="center">Email</TableCell>
                    {/* <TableCell style={{ fontWeight: 'bold', fontSize: "20px" }} align="center">Registration Number</TableCell> */}
                    {/* <TableCell style={{ fontWeight: 'bold', fontSize: "20px" }} align="center">Number of License</TableCell> */}
                    <TableCell align="center" style={{ width: "270px", fontWeight: 'bold', fontSize: "20px" }}>
                      Type
                    </TableCell>
                    {/* <TableCell align="right"></TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((row) => (
                    <TableRow
                      key={row._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      onClick={() => handleRowClick(row)}
                      style={{ cursor: "pointer" }}
                    >
                      <TableCell>{row.name}</TableCell>
                      <TableCell align="center">{row.email}</TableCell>
                      {/* <TableCell align="center">{row.registrationNumber === "NOT_AVAILABLE" ? "Not Available" : row.registrationNumber}</TableCell> */}
                      {/* <TableCell align="center">
                        {row.numberOfLicense ? row.numberOfLicense : "NA"}
                      </TableCell> */}
                      <TableCell align="center" style={{ width: "270px" }}>
                        {renderStyledDiv(row)}
                      </TableCell>
                      {/* <TableCell align="right">
                        <div>
                          {COUPON_SYSTEM_ENTITIES.includes(row.type) && (
                            <Button
                              color="success"
                              id={row._id}
                              variant="contained"
                              onClick={(e) => handleOpenCouponModal(e, row)}
                            >
                              Add Coupon
                            </Button>
                          )}
                          {CORPORATE_SYSTEM_ENTITES.includes(row.type) && (
                            <Button
                              id={row._id}
                              variant="contained"
                              onClick={(e) => handleOpenModal(e, row)}
                            >
                              Add Plan
                            </Button>
                          )}
                        </div>
                      </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
          <AddPlansForCorporateModal
            isOpen={isModalOpen}
            onClose={closeModal}
            selectedEntity={addPlanData}
            handleCheckClick={handleCheckClick}
            getCorporateData={getCorporateData}
          />
        </>
      ) : (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={handleBackButton}
            style={{ marginTop: 14 }}
          >
            Back
          </Button>
          <DetailsView
          refetch={refetch}
          getDoctorList={getDoctorList}
            rowData={selectedRow}
            setIsTableVisible={setIsTableVisible}
            apiData={apiData}
            doctorList={doctorList}
            patientData={patientData}
            selectedRow={selectedRow}
          />
        </>
      )}
    </div>
  );
};


const renderStyledDiv = (row) => {
  // Define styles based on row type
  console.log("ARE WE HARE ?",row)
  let backgroundColor, textColor, content;
console.log(row)

  switch (row.type) {
    case "PSYCHOLOGIST":
      backgroundColor = "#d2691e";
      textColor = "white";
      content = "Mental Health Professional";
      break;
    case "HOSPITAL":
      backgroundColor = "green";
      textColor = "white";
      content = "Medical Facility";
      break;
    case "INTEGRATION":
      backgroundColor = "green";
      textColor = "white";
      content = "Integration";
      break;
    default:
      backgroundColor = "orange";
      textColor = "white";
      content = ENTITY_TYPES[row.type] || "";
  }

  if(row.isTrial) content = "Trial"


  // Define common style properties
  const commonStyle = {
    cursor: "none",
    backgroundColor,
    color: textColor,
    height: "45px",
    alignContent: "center",
    border: "5px solid",
    borderRadius: "18px",
  };

  // Render the div with the extracted styles and content
  return <div style={commonStyle}>{content}</div>;
};


export default CorporateTable;
