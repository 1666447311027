import React, { useState } from 'react';
import useStyles from './CommonStyles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormControlLabel,
  Button,
  Checkbox,
  DialogActions,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { EditIcon } from 'lucide-react';
import { DoctorService } from '../../../../services';
import { showErrorMessage, showSuccessMessage } from '../../../../managers/utility';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../AuthContext';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationDialog from '../../../../common/confirmationDialog';
import AddEditDoctor from '../../../CorporateConsole/CorporateDashboard/PatientPortal.js/AddEditDoctor';

const CommonDetails = ({ doctorList = [], patientData = [], organizationData = [] ,getDoctorList}) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;

  // State to track visibility of passwords
  const [visiblePasswords, setVisiblePasswords] = useState({});
  const [doctorToDelete, setDoctorToDelete] = useState(null);

  // Toggle visibility for a specific row
  const togglePasswordVisibility = index => {
    setVisiblePasswords(prev => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const [open, setOpen] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const { userDetails } = useAuth();
  const navigate = useNavigate();

  const handleEditClick = doctor => {
    setSelectedDoctor(doctor);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedDoctor(null);
  };


  const handleDeleteDoctor = async () => {
    try {
      const response = await DoctorService.deleteDoctor(doctorToDelete);

      if (response) {
        showSuccessMessage('Doctor Deleted Successfully');
        getDoctorList()
        handleClose(); // Close the modal
      }
    } catch (error) {
      console.error('Error deleting doctor:', error);
    }
  };

  return (
    <>
      {/* Organization Directory */}
      <div className={classes.patientPortal}>
        <Typography variant="h5" style={{ marginBottom: '20px', fontWeight: '500' }}>
          Organization Directory
        </Typography>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: '#f5f5f5' }}>
                <TableCell>
                  <Typography variant="subtitle2">Name</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">Entity Id</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">Affiliation</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {organizationData.length > 0 ? (
                organizationData.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((organization, index) => (
                  <TableRow key={index} hover className={classes.clickableRow}>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <PersonIcon style={{ marginRight: '8px', color: '#757575' }} />
                        {organization.name || 'N/A'}
                      </Box>
                    </TableCell>
                    <TableCell>{organization.entityId || 'N/A'}</TableCell>
                    <TableCell>{organization.affilatedEntityName || 'N/A'}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    No Organization Available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {/* Doctor Directory */}
      <div className={classes.patientPortal}>
        <Typography variant="h5" style={{ marginBottom: '20px', fontWeight: '500' }}>
          Doctor Directory
        </Typography>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: '#f5f5f5' }}>
                <TableCell>
                  <Typography variant="subtitle2">Name</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">Email</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">Password</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">Actions</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {doctorList.length > 0 ? (
                doctorList.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((doctor, index) => (
                  <TableRow key={index} hover className={classes.clickableRow}>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <PersonIcon style={{ marginRight: '8px', color: '#757575' }} />
                        {doctor.name || 'N/A'}
                      </Box>
                    </TableCell>
                    <TableCell>{doctor.email || 'N/A'}</TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        {visiblePasswords[index] ? doctor.passwordString || 'N/A' : '••••••'}
                        <IconButton size="small" onClick={() => togglePasswordVisibility(index)}>
                          {visiblePasswords[index] ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleEditClick(doctor)}>
                        <EditIcon />
                      </IconButton>
                      <ConfirmationDialog actionOnConfirm={handleDeleteDoctor}>
                        <IconButton onClick={() => setDoctorToDelete(doctor._id)}>
                          <DeleteIcon />
                        </IconButton>
                      </ConfirmationDialog>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    No Doctor Available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {selectedDoctor && <AddEditDoctor getDoctorList={getDoctorList} doctor={selectedDoctor} setDoctor={setSelectedDoctor} handleClose={handleClose} open={open} editMode={true}/>}

      {/* Patient Directory */}
      <div className={classes.patientPortal}>
        <Typography variant="h5" style={{ marginBottom: '20px', fontWeight: '500' }}>
          Patient Directory
        </Typography>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: '#f5f5f5' }}>
                <TableCell>
                  <Typography variant="subtitle2">Name</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">Email</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">Mobile</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {patientData.length > 0 ? (
                patientData.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((patient, index) => (
                  <TableRow key={index} hover className={classes.clickableRow}>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <PersonIcon style={{ marginRight: '8px', color: '#757575' }} />
                        {patient.name || 'N/A'}
                      </Box>
                    </TableCell>
                    <TableCell>{patient.email || 'N/A'}</TableCell>
                    <TableCell>{patient.mobile || 'N/A'}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    No Patient Available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default CommonDetails;
