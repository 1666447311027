import { useEffect, useState } from "react"
import useStyles from "../doctorStyles"
import { Tabs, Tab, Paper, Typography, Button } from "@mui/material"
import {
  Person as PersonIcon,
  ArrowBack as ArrowBackIcon,
  Assessment as AssessmentIcon,
  Event as EventIcon,
  Book as BookIcon,
  Notes as NotesIcon
} from "@mui/icons-material"
import JournalTab from "./JournalTab"
import AssessmentTab from "./AssessmentTab"
import PatientAppointmentsTab from "./PatientAppointmentsTab"
import { AuthUserService } from "../../../../../services"
import { timeOptions } from "../data/mockData"
import PatientNotes from "./PatientNotes"

const PatientDetails = ({
  patient,
  setSelectedPatient,
  appointments,
  answerSessionCount,
  page,
  setPage,
  setSelectedAppointment,
}) => {
  const classes = useStyles()
  const [patientInfoTab, setPatientInfoTab] = useState(0)
  const [journalData, setJournalData] = useState([])
  const [timeFilter, setTimeFilter] = useState(timeOptions[0].value);


  useEffect(() => {
    getJournalData()
  }, [timeFilter])

  const getJournalData = async () => {
    console.log("timeFilter",timeFilter)
    const currentDate = new Date();
    const startDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - timeFilter,
      0,
      0,
      0,
      0
    ).getTime();
    const lastDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    const endDate = new Date(
      lastDay.getFullYear(),
      lastDay.getMonth(),
      lastDay.getDate(),
      23,
      59,
      59,
      999
    ).getTime();
    let requestData = {
      startDate: startDate,
      endDate: endDate,
      userId: patient._id,
    };
    const data = await AuthUserService.getUserJournalData(requestData);
    console.log("datadatadata",data)
    const sortedData = data.sort((a, b) => new Date(a.date) - new Date(b.date));
    setJournalData(sortedData);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        className={classes.backButton}
        startIcon={<ArrowBackIcon />}
        style={{marginBottom:10}}
        onClick={() => setSelectedPatient(null)}
      >
        Back
      </Button>

      <Paper className={classes.patientDetails}>
        <div className={classes.patientHeader}>
          <div className={classes.patientIcon}>
            <PersonIcon />
          </div>
          <div>
            <Typography variant="h5" style={{ fontWeight: "500" }}>
              {patient.name}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {patient.email} • {patient.mobile}
            </Typography>
          </div>
        </div>

        <Tabs
          value={patientInfoTab}
          onChange={(e, newValue) => setPatientInfoTab(newValue)}
          className={classes.subTabs}
          indicatorColor="primary"
          textColor="primary"
          sx={{
            "& .MuiTabs-indicator": {
              display: "none", // Hide default bottom indicator
            },
          }}
        >
          <Tab label="Journal" icon={<BookIcon />} iconPosition="start" />
          <Tab label="Assessment" icon={<AssessmentIcon />} iconPosition="start" />
          <Tab label="Appointments" icon={<EventIcon />} iconPosition="start" />
          <Tab label="Notes" icon={<NotesIcon />} iconPosition="start" />

        </Tabs>

        <div className={classes.tabContent}>
          {patientInfoTab === 0 && <JournalTab journalData={journalData} timeFilter={timeFilter} setTimeFilter={setTimeFilter}/>}

          {patientInfoTab === 1 && (
            <AssessmentTab answerSessionCount={answerSessionCount} page={page} setPage={setPage} />
          )}

          {patientInfoTab === 2 && (
            <PatientAppointmentsTab
              appointments={appointments}
              page={page}
              setPage={setPage}
              setSelectedAppointment={setSelectedAppointment}
            />
          )}

{patientInfoTab === 3 && (
            <PatientNotes
              appointments={appointments}
              page={page}
              setPage={setPage}
              setSelectedAppointment={setSelectedAppointment}
            />
          )}
        </div>
      </Paper>
    </>
  )
}

export default PatientDetails