import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
  patientPortal: {
    padding: "20px",
    backgroundColor: "white",
  },
  bottomNav: {
    marginBottom: "20px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    overflow: "hidden",
  },
  tableContainer: {
    marginBottom: "20px",
    borderRadius: "8px",
    boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
    overflow: "hidden",
  },
  clickableRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f0f7ff",
    },
  },
  patientDetails: {
    border: "1px solid #e0e0e0",
    padding: "20px",
    borderRadius: "12px",
    backgroundColor: "#fff",
    boxShadow: "0 2px 10px rgba(0,0,0,0.05)",
    marginBottom: "20px",
  },
  patientHeader: {
    display: "flex",
    alignItems: "center",
    marginBottom: "16px",
    padding: "10px 0",
    borderBottom: "1px solid #eaeaea",
  },
  patientIcon: {
    backgroundColor: "#e3f2fd",
    padding: "8px",
    borderRadius: "50%",
    marginRight: "12px",
  },
  textField: {
    marginTop: "10px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
    },
  },
  appointmentCard: {
    marginTop: "10px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.08)",
    transition: "transform 0.2s",
    "&:hover": {
      transform: "translateY(-2px)",
      boxShadow: "0 4px 8px rgba(0,0,0,0.12)",
    },
  },
  appointmentDate: {
    display: "flex",
    alignItems: "center",
    color: "#1976d2",
    fontWeight: "500",
  },
  appointmentIcon: {
    marginRight: "8px",
    color: "#1976d2",
  },
  assessment: {
    marginTop: "10px",
    padding: "16px",
    backgroundColor: "#e8f5e9",
    borderRadius: "8px",
    border: "1px solid #c8e6c9",
  },
  assessmentItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
  },
  scoreChip: {
    marginLeft: "8px",
    fontWeight: "bold",
  },
  backButton: {
    marginBottom: "10px",
    borderRadius: "8px",
    textTransform: "none",
    boxShadow: "none",
    padding: "8px 16px",
  },
  submitButton: {
    marginTop: "16px",
    borderRadius: "8px",
    textTransform: "none",
    padding: "8px 16px",
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
  },
  tabContent: {
    minHeight: "300px",
  },
  journalField: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#fff",
      borderRadius: "8px",
    },
  },
  notesContainer: {
    backgroundColor: "#fff",
    padding: "16px",
    borderRadius: "8px",
    border: "1px solid #e0e0e0",
  },
  notesActions: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "16px",
  },
})

export default useStyles;