// Sample data for patients
export const dummyPatients = [
    {
      _id: "1",
      name: "John Doe",
      email: "john.doe@example.com",
      mobile: "+1234567890",
    },
    {
      _id: "2",
      name: "Jane Smith",
      email: "jane.smith@example.com",
      mobile: "+0987654321",
    },
    {
      _id: "3",
      name: "Robert Johnson",
      email: "robert.johnson@example.com",
      mobile: "+1122334455",
    },
  ];
  
  // Sample appointments data
  export const appointments = [
    {
      patientId: "#123",
      patientName: "Oliver Smith",
      avatar: "/placeholder.svg?height=40&width=40",
      type: "Clinic Visit",
      time: "08:00 AM - 08:30 AM",
      mobileNumber: "+447123456789",
      status: "Completed",
    },
    {
      patientId: "#1234",
      patientName: "William Harris",
      avatar: "/placeholder.svg?height=40&width=40",
      type: "Home Visit",
      time: "08:30 AM - 09:30 AM",
      mobileNumber: "+447123987654",
      status: "Accepted",
    },
    {
      patientId: "#1234",
      patientName: "Sophia Taylor",
      avatar: "/placeholder.svg?height=40&width=40",
      type: "Home Visit",
      time: "09:00 AM - 09:30 AM",
      mobileNumber: "+447123678945",
      status: "Accepted",
    },
    {
      patientId: "#1234",
      patientName: "Henry Wilson",
      avatar: "/placeholder.svg?height=40&width=40",
      type: "Clinic Visit",
      time: "09:30 AM - 10:00 AM",
      mobileNumber: "+447123543216",
      status: "Rescheduled",
    },
    {
      patientId: "#1234",
      patientName: "Alexa Carey",
      avatar: "/placeholder.svg?height=40&width=40",
      type: "Clinic Visit",
      time: "10:00 AM - 10:30 AM",
      mobileNumber: "+447123678912",
      status: "Accepted",
    },
  ];
  
  export const timeOptions = [
    { label: "Last 7 days", value: 7 },
    { label: "Last 15 days", value: 15 },
    { label: "Last 2 months", value: 60 }, // Assuming 30 days in a month
    { label: "Last 3 months", value: 90 }, // 3 * 30 days
  ];
