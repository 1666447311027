import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import settingsModule from "../../../services/settings";
import { showErrorMessage } from "../../../managers/utility";
import MobileLLMPrompt from "./MobileLLMPrompt";
import { Button, Switch, Grid } from "@mui/material";

export default function SalesIQAPIConfig() {

  const [salesIQKeysIOS, setSalesIQKeysIOS] = useState({ accessKey: "", apiKey: "" });
  const [salesIQKeysAndroid, setSalesIQKeysIOSAndroid] = useState({ accessKey: "", apiKey: "" });

 
  const handleSalesIQChangeforiOS = (event, field) => {
    setSalesIQKeysIOS((prevKeys) => ({ ...prevKeys, [field]: event.target.value }));
  };

  const handleSalesIQChangeforAndroid = (event, field) => {
    setSalesIQKeysIOSAndroid((prevKeys) => ({ ...prevKeys, [field]: event.target.value }));
  };

  const getCurrentValue = async () => {
    try {
      const setting = await settingsModule.getSettings();
      console.log(setting, "setting");
      if (setting && setting._id) {
        setSalesIQKeysIOS({
          accessKey: setting?.SalesIQAPIKeyIOS?.accessKey || "",
          apiKey: setting?.SalesIQAPIKeyIOS?.apiKey || "",
        });
      }
      if (setting && setting._id) {
        setSalesIQKeysIOSAndroid({
          accessKey: setting?.SalesIQAPIKeyAndroid?.accessKey || "",
          apiKey: setting?.SalesIQAPIKeyAndroid?.apiKey || "",
        });
      }
    } catch (error) {
      showErrorMessage("Error occurred while fetching settings.");
    }
  };

  useEffect(() => {
    getCurrentValue();
  }, []);

  const updateSettingsForiOS = async () => {
    try {
      await settingsModule.updateSettings({
      
        SalesIQAPIKeyIOS: { accessKey : salesIQKeysIOS.accessKey, apiKey : salesIQKeysIOS.apiKey},

      });
      getCurrentValue();
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const updateSettingsForAndorid = async () => {
    try {
      await settingsModule.updateSettings({
      
        SalesIQAPIKeyAndroid: { accessKey : salesIQKeysAndroid.accessKey, apiKey : salesIQKeysAndroid.apiKey},

      });
      getCurrentValue();
    } catch (error) {
      showErrorMessage(error);
    }
  };

  return (
    <div style={{ marginTop: "50px" }}>

      <Typography sx={{ mt: 4 }}>SalesIQ API Keys For iOS</Typography>
      <TextField
        sx={{ mt: 2, mr: 2 }}
        label="Access Key"
        variant="outlined"
        value={salesIQKeysIOS.accessKey}
        onChange={(e) => handleSalesIQChangeforiOS(e, "accessKey")}
      />
      <TextField
        sx={{ mt: 2, mr: 2 }}
        label="API Key"
        variant="outlined"
        value={salesIQKeysIOS.apiKey}
        onChange={(e) => handleSalesIQChangeforiOS(e, "apiKey")}
      />

      <Button variant="contained" style={{ marginTop: 26 }} onClick={updateSettingsForiOS}>
        Update iOS Keys
      </Button>


      <Typography sx={{ mt: 4 }}>SalesIQ API Keys For Android</Typography>
      <TextField
        sx={{ mt: 2, mr: 2 }}
        label="Access Key"
        variant="outlined"
        value={salesIQKeysAndroid.accessKey}
        onChange={(e) => handleSalesIQChangeforAndroid(e, "accessKey")}
      />
      <TextField
        sx={{ mt: 2, mr: 2 }}
        label="API Key"
        variant="outlined"
        value={salesIQKeysAndroid.apiKey}
        onChange={(e) => handleSalesIQChangeforAndroid(e, "apiKey")}
      />

      <Button variant="contained" style={{ marginTop: 26 }} onClick={updateSettingsForAndorid}>
        Update Android Keys
      </Button>

    </div>
    
  );
}
