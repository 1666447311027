import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Paper, TextField } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { ReportRemarkService } from '../../../services';
import { useAuth } from '../../../AuthContext';
import { showErrorMessage } from '../../../managers/utility';

export default function BasicPopover({selectedSession,getReportRemarks}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [remark, setRemark] = React.useState("")
  
  const { userDetails } = useAuth()

  const addReportRemark = async () => {
    if(!userDetails._id || !selectedSession.sessionId || selectedSession.remark){
        showErrorMessage("Something went wrong")
    }

    // remarkTypeId can be user or session 
    const res = await ReportRemarkService.addReportRemark({ remarkProviderId: userDetails._id, remarkTypeId: selectedSession.sessionId, remark: remark })
    if(res && res._id){
        handleClose();
        getReportRemarks();
        setRemark("")
    }
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button aria-describedby={id} variant="contained" onClick={handleClick}>
        Add Remarks
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Paper elevation={2} sx={{ padding: '10px 20px 10px 20px', display: 'flex', justifyContent: 'space-between', width: '50vw' }}>
          <TextField value={remark} onChange={(e) => setRemark(e.target.value)} placeholder="Remarks" fullWidth sx={{marginRight:1}}/>
          <Button endIcon={<DoneIcon />} onClick={() => addReportRemark()} variant="contained" />
        </Paper>
      </Popover>
    </div>
  );
}
