import { httpConstants } from "../constants";
import { httpService } from "../utility/httpService";
import { getHeaders } from "./baseFunctions";



const doctorModule = {
    addDoctor,
    getAllDoctors,
    getDoctorsByEntityService,
    getDoctorAppointments,
    updateAppointmentStatus,
    updateDoctor,
    deleteDoctor
    
};

export default doctorModule;

async function getAllDoctors(requestData) {
  try {
    let url = process.env.REACT_APP_API_URI + `/api/user/get-doctors-list`
    const response = await httpService(
      httpConstants.METHOD_TYPE.GET,
      getHeaders(),
      requestData,
      url,
    );
    if (!response) return Promise.reject(response);
    return Promise.resolve(response.responseData);
  } catch (err) {
    return Promise.reject(err);
  }
}

async function addDoctor(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/doctor/add-doctor`
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(),requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}


async function getDoctorsByEntityService(requestData) {
    let url = `${process.env.REACT_APP_API_URI}/api/user/get-doctor-by-entity/${requestData.entityId}`;

    return httpService(httpConstants.METHOD_TYPE.GET, getHeaders(), requestData, url)
        .then(response => {
            if (!response.success || response.responseCode !== 200 || !response.responseData) {
                return Promise.reject(response);
            }
            return Promise.resolve(response.responseData);
        })
        .catch(err => {
            return Promise.reject(err);
        });
}

async function getDoctorAppointments(requestData) {
    console.log('requestData on service', requestData)
    let url = `${process.env.REACT_APP_API_URI}/api/user/get-appointments-by-doctorId/${requestData.doctorId}`;

    return httpService(httpConstants.METHOD_TYPE.GET, getHeaders(), requestData, url)
        .then(response => {
            if (!response.success || response.responseCode !== 200 || !response.responseData) {
                return Promise.reject(response);
            }
            return Promise.resolve(response.responseData);
        })
        .catch(err => {
            return Promise.reject(err);
        });
}

async function updateAppointmentStatus(requestData) {
   
        let url = process.env.REACT_APP_API_URI + `/api/user/update-appointment-status`;
        return httpService(httpConstants.METHOD_TYPE.PUT, getHeaders(), requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
    }


async function updateDoctor(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/update-doctor-profile/${requestData._id}`
    return httpService(httpConstants.METHOD_TYPE.PUT, getHeaders(), requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function deleteDoctor(doctorId) {
    let url = process.env.REACT_APP_API_URI + `/api/private/delete-doctor-profile/${doctorId}`;
    
    return httpService(httpConstants.METHOD_TYPE.DELETE, getHeaders(), {}, url)  // Ensure the body is NULL
        .then(response => {
            if (!response.success || response.responseCode !== 200 || !response.responseData)
                return Promise.reject(response);
            return Promise.resolve(response.responseData);
        })
        .catch(err => Promise.reject(err));
}



