import React from "react";
import { useEffect, useState } from "react";
import useStyles from "./patientStyles";
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Checkbox,
  Typography,
} from "@mui/material";
import { DoctorService } from "../../../../services";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../managers/utility";

export default function AddEditDoctor({
  getDoctorList,
  doctor,
  userDetails,
  setDoctor,
  handleClose,
  open,
  editMode,
}) {
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setDoctor((prev) => {
      if (!prev) return {}; // Ensure prev is not undefined

      let newSpecialization = prev.specialization;
      let newIsOnline = prev.isOnline;

      if (name === "isPsychologist") {
        newSpecialization = checked
          ? "PSYCHOLOGIST"
          : prev.specialization === "PSYCHOLOGIST"
          ? ""
          : prev.specialization;
      } else if (name === "isPsychiatrist") {
        newSpecialization = checked
          ? "PSYCHIATRIST"
          : prev.specialization === "PSYCHIATRIST"
          ? ""
          : prev.specialization;
      }

      if (name === "islocationOnline") {
        newIsOnline = checked;
      }

      return {
        ...prev,
        specialization: newSpecialization,
        isOnline: newIsOnline,
      };
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setDoctor((prev) => ({
      ...prev,
      [name]: value,
      isOnline: name.startsWith("location") ? false : prev.isOnline, // Uncheck if user types manually
    }));
  };

  const handleAddDoctor = async () => {
    try {
      let requestData = { ...doctor };
      // Validate email before sending request
      const lowerCaseEmail = doctor.email.toLowerCase();
      if (doctor.email !== lowerCaseEmail) {
        showErrorMessage("Email should be in lowercase only.");
        return;
      }

      if (!lowerCaseEmail.includes("@") || !lowerCaseEmail.includes(".")) {
        showErrorMessage("Invalid email format.");
        return;
      }

      const domainParts = lowerCaseEmail.split("@")[1]?.split(".");
      if (
        !domainParts ||
        domainParts.length < 2 ||
        domainParts.some((part) => part === "")
      ) {
        showErrorMessage("Invalid email format.");
        return;
      }

      requestData.email = lowerCaseEmail; // Ensure lowercase before sending

      if (!editMode) requestData["entityId"] = userDetails._id;
      const response = editMode
        ? await DoctorService.updateDoctor(requestData)
        : await DoctorService.addDoctor(requestData);

      if (response._id) {
        showSuccessMessage(
          `Doctor ${editMode ? "Updated" : "Added "} Successfully`
        );
        getDoctorList();

        setDoctor({
          name: "",
          email: "",
          password: "",
          specialization: "",
          experience: "",
          designation: "",
          phone: "",
          staffId: "",
        }); // Reset form fields
        handleClose(); // Close the modal
      } else {
        showErrorMessage(
          response.error || response.message || "Unable To Add Doctor"
        );
      }
    } catch (error) {
      console.error("Error adding doctor:", error);
      showErrorMessage(error.message);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>{editMode ? "Edit" : "Add"} Doctor</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Name"
          name="name"
          fullWidth
          value={doctor.name}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Email"
          name="email"
          type="email"
          fullWidth
          value={doctor.email}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Staff Id"
          name="staffId"
          fullWidth
          value={doctor.staffId}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Appointment Link"
          name="appointmentLink"
          fullWidth
          value={doctor.appointmentLink}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Experience (Years)"
          name="experience"
          type="number"
          fullWidth
          value={doctor.experience}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Designation"
          name="designation"
          fullWidth
          value={doctor.designation}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Image URL"
          name="imageUrl"
          fullWidth
          value={doctor.imageUrl}
          onChange={handleChange}
        />
        <Typography style={{ marginTop: "7px" }}> Specialization</Typography>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={doctor.specialization === "PSYCHOLOGIST"}
                onChange={handleCheckboxChange}
                name="isPsychologist"
              />
            }
            label="Psychologist"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={doctor.specialization === "PSYCHIATRIST"}
                onChange={handleCheckboxChange}
                name="isPsychiatrist"
              />
            }
            label="Psychiatrist"
          />
        </Box>
        <TextField
          margin="dense"
          label="Phone"
          name="phone"
          fullWidth
          value={doctor.phone}
          onChange={handleChange}
        />
        <Typography style={{ marginTop: "7px" }}>Location</Typography>

        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={doctor.isOnline}
                onChange={handleCheckboxChange}
                name="islocationOnline"
              />
            }
            label="ONLINE"
          />
        </Box>

        <TextField
          margin="dense"
          label="Location"
          name="location"
          fullWidth
          value={doctor.location}
          onChange={handleChange}
        />

        <TextField
          margin="dense"
          label="Location 2"
          name="location2"
          fullWidth
          value={doctor.location2}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleAddDoctor} color="primary" variant="contained">
          {editMode ? "Edit" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
