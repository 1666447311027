import React from 'react';
import { useEffect, useState } from 'react';
import useStyles from './patientStyles';
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Checkbox,
  Typography,
} from '@mui/material';
import { useAuth } from '../../../../AuthContext';

import { DoctorService, EntityService, PatientService } from '../../../../services';
import authModule from '../../../../services/authUser';
import { showErrorMessage, showSuccessMessage } from '../../../../managers/utility';
import answerSessionServiceModule from '../../../../services/answerSession';
import { useNavigate } from 'react-router-dom';
import CommonDetails from '../../../AdminConsole/Entity/CommonDetails/CommonDocAndPatientDetails';
import AddEditDoctor from './AddEditDoctor';

const PatientPortal = () => {
  const classes = useStyles();
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [apiData, setApiData] = useState([]);
  const [page, setPage] = useState(1);
  const { userDetails } = useAuth();
  const [userDetailsData, setUserDetailsData] = useState({});
  const [answerSessionCount, setAnswerSessionCount] = useState([]);
  const [open, setOpen] = useState(false);
  const [patientData, setPatientData] = useState([]);
  const [doctor, setDoctor] = useState({
    name: '',
    email: '',
    password: '',
    specialization: '',
    experience: '',
    designation: '',
    imageUrl: '',
    phone: '',
    staffId: '',
    link: '',
    location: '',
    location2: '',
    isOnline: false,
  });

  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [doctorList, setDoctorList] = useState([]);
  const [organizationData, setOrganizationData] = useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();


  console.log('userDetailsData', userDetailsData);
  const rowsPerPage = 10;
  const rowsOnAssessment = 5;

  const getDoctorList = async () => {
    if (userDetails && userDetails?._id) {
      const doctors = await DoctorService.getDoctorsByEntityService({
        entityId: userDetails?._id,
      });
      setDoctorList(doctors);
    } else {
      // showErrorMessage("Error in finding your hospital")
    }
  };

  const getPatientList = async () => {
    if (userDetails && userDetails?._id) {
      const patients = await PatientService.getPatientByEntityService({
        entityId: userDetails?._id,
      });
      console.log(patients, 'doctors by hospitals');
      setPatientData(patients);
    } else {
      // showErrorMessage("Error in finding your hospital")
    }
  };

  useEffect(() => {
    getOrganizationDataByHospital();
    getPatientList();
    getDoctorList();
    fetchData();
    if (selectedPatient) {
      getAnswerSessions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails, selectedPatient]);

  const getCorporateData = async () => {
    try {
      const response = await EntityService.getEntityEmployees(userDetails._id || '');
      setApiData(response);
    } catch (error) {
      console.error('Error fetching corporate data:', error);
    }
  };

  const fetchData = async () => {
    let email = 'sarthaknexus+20@gmail.com';
    if (email.trim() !== '') {
      try {
        const response = await authModule.getUserDetailsByEmail({ email });
        console.log('response', response);
        if (response && response.data) {
          setUserDetailsData(response.data); // Set data from response
        } else {
          console.log('no user exists ');
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    } else {
      showErrorMessage('Please Enter Email');
    }
  };

  const getAnswerSessions = async () => {
    console.log('trigeerring');
    try {
      if (selectedPatient && selectedPatient._id) {
        let answerSession = await answerSessionServiceModule.getAllSessions(0, 0, selectedPatient._id);
        console.log('res', answerSession);
        setAnswerSessionCount(answerSession?.data);
      }
    } catch (error) {
      console.error('Error fetching answer sessions:', error);
    }
  };


  const getOrganizationDataByHospital = async () => {
    if (userDetails && userDetails?._id) {
      const organization = await EntityService.getOrganizationByHospital({
        entityId: userDetails?._id,
      });
      console.log(organization, 'patients');
      setOrganizationData(organization);
    } else {
      showErrorMessage('Error in finding your Organization');
    }
  };

  return (
    <div className={classes.patientPortal}>
      <div>
        <Button variant="contained" color="primary" onClick={handleOpen}>
          Add Doctor
        </Button>
      </div>

      <AddEditDoctor
      getDoctorList={getDoctorList}
        doctor={doctor}
        setDoctor={setDoctor}
        handleClose={handleClose}
        userDetails={userDetails}
        open={open}
      />
      <CommonDetails getDoctorList={getDoctorList} doctorList={doctorList} patientData={patientData} organizationData={organizationData} />
    </div>
  );
};

export default PatientPortal;
