import { useEffect, useState } from 'react';
import useStyles from '../doctorStyles';
import { Typography, Box, Paper, TextField, Button, Divider } from '@mui/material';
import { Save as SaveIcon } from '@mui/icons-material';
import { useAuth } from '../../../../../AuthContext';
import { ReportRemarkService } from '../../../../../services';
import { showErrorMessage, showSuccessMessage } from '../../../../../managers/utility';
import moment from 'moment';

const PatientNotes = ({ patients, page, setPage, setSelectedPatient }) => {
  const classes = useStyles();
  const { userDetails } = useAuth();
  const [remark, setRemark] = useState('');
  const [remarkDatas, setRemarksData] = useState('');

  useEffect(() => {
    getReportRemarks();
  }, []);

  const getReportRemarks = async () => {
    const remarksAPI = await ReportRemarkService.getReportRemark({ remarkProviderId: userDetails._id, remarkTypeId: userDetails._id });
    if (remarksAPI) setRemarksData(remarksAPI);
  };

  const addReportRemark = async () => {
    if (!userDetails._id || !remark) {
      showErrorMessage('Something went wrong');
      return;
    }

    try {
      const res = await ReportRemarkService.addReportRemark({
        remarkTypeId: userDetails._id, // Leave sessionId blank
        remarkProviderId: userDetails._id,
        remark: remark,
      });

      if (res && res._id) {
        showSuccessMessage('Added Successfully');
        getReportRemarks();
        setRemark('');
      }
    } catch (error) {
      showErrorMessage('Failed to add remark', error);
    }
  };

  return (
    <Paper style={{ padding: '20px', marginTop: '20px' }}>
      <Box mt={4}>
        <Typography variant="h6" gutterBottom>
          Patient Notes History
        </Typography>

        <Box
          sx={{
            maxHeight: '300px', // Set a scrollable height
            overflowY: 'auto', // Enable vertical scroll
            border: '1px solid #ddd',
            borderRadius: '8px',
            padding: '10px',
          }}
        >
          {remarkDatas.length > 0 ? (
            remarkDatas.map((remark, index) => (
              <Box key={index} mb={2}>
                <Typography variant="body1" style={{ wordBreak: 'break-word' }}>
                  {remark.remark}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {moment(remark.createdAt).format('DD MMM YYYY, hh:mm A')}
                </Typography>
                <Divider style={{ marginTop: '8px' }} />
              </Box>
            ))
          ) : (
            <Typography variant="body2" color="textSecondary">
              No remarks available.
            </Typography>
          )}
        </Box>
      </Box>
      <Typography variant="h6" gutterBottom>
        Add Patient Notes
      </Typography>
      <TextField
        multiline
        rows={4}
        fullWidth
        variant="outlined"
        placeholder="Enter patient's notes here..."
        className={classes.journalField}
        value={remark}
        onChange={e => setRemark(e.target.value)}
      />
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button variant="contained" color="primary" startIcon={<SaveIcon />} className={classes.submitButton} onClick={addReportRemark}>
          Save Notes
        </Button>
      </Box>
    </Paper>
  );
};

export default PatientNotes;
