import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';

import DoctorPortal from './Doctors/DoctorPortal/doctorPortal';
import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import EntityFareIcon from '@mui/icons-material/CorporateFare';

const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const [isLoading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('Doctor');

  useEffect(() => {
    // Retrieve the active tab name from local storage
    const storedTab = localStorage.getItem('activeTab');
    if (storedTab) {
      setActiveTab(storedTab);
    }
  }, []);

  const handleTabClick = tabName => {
    setActiveTab(tabName);
    // Store the selected tab name in local storage
    localStorage.setItem('activeTab', tabName);
  };

  let component;

  switch (activeTab) {
    case 'Doctor':
      component = <DoctorPortal />;
      break;
    default:
  }

  const adminSideBar = () => (
    <List>
      <ListItem disablePadding>
        <ListItemButton sx={{ backgroundColor: activeTab === 'Doctor' ? '#cfcfcf' : '' }} onClick={() => handleTabClick('Doctor')}>
          <EntityFareIcon sx={{ marginRight: 1 }} />
          <ListItemText primary="Doctor Dashboard" />
        </ListItemButton>
      </ListItem>
    </List>
  );

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      {adminSideBar()}
      <Divider />
    </div>
  );

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, zIndex: 0 }} aria-label="mailbox folders">
              {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
              <Drawer
                variant="permanent"
                sx={{
                  display: { xs: 'none', sm: 'block' },
                  '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
                open
              >
                {drawer}
              </Drawer>
            </Box>
            <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
              {component}
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
