import { useEffect, useState } from 'react';
import useStyles from '../doctorStyles';
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  Avatar,
  Chip,
  Pagination,
  IconButton,
} from '@mui/material';
import { MoreHoriz as MoreHorizIcon } from '@mui/icons-material';
import { getStatusColor, getStatusTextColor } from '../utils/helpers';
import { DoctorService } from '../../../../../services';
import { useAuth } from '../../../../../AuthContext';
import moment from 'moment';

const PatientAppointmentsTab = ({ appointments, page, setPage, setSelectedAppointment }) => {
  const classes = useStyles();
  const rowsOnAssessment = 5;
  const rowsPerPage = 10;
    const { userDetails } = useAuth();
  
  const [appointmentsData, setAppointmentsData] = useState([]);


  useEffect(() => {
    if (userDetails?._id) {
      getDocAppointments();
    }
  }, [userDetails]);

  const getDocAppointments = async () => {
    try {
      const response = await DoctorService.getDoctorAppointments({ doctorId: userDetails?._id });
      setAppointmentsData(response || []);
    } catch (error) {
      console.error('Error fetching appointments:', error);
      setAppointmentsData([]);
    }
  };
  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Upcoming Appointments
      </Typography>
      <TableContainer component={Paper} style={{ boxShadow: 'none', border: '1px solid #f0f0f0' }}>
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: '#f9fafb', borderBottom: '1px solid #e5e7eb' }}>
              <TableCell>
                <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                  Booking Id
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                  Patient Name
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                  Duration
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                  Time
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                  Booking Date
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                  Status
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {appointmentsData.length > 0 ? (
              appointmentsData.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((appointment, index) => (
                <TableRow key={index} hover style={{ cursor: 'pointer', backgroundColor: index % 2 === 0 ? '#ffffff' : '#fafafa' }}>
                  <TableCell>
                    <Typography variant="body2">{appointment.bookingId}</Typography>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Avatar
                        src={appointment.avatar}
                        alt={appointment.patientName}
                        style={{ marginRight: '12px', width: '32px', height: '32px' }}
                      />
                      <Typography variant="body2">{appointment.patientName}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">{appointment.duration}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">{moment(appointment.startTime).format('DD-MMM-YY')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">{moment(appointment.bookedOn).format('DD-MMM-YY')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={appointment.status}
                      size="small"
                      style={{
                        backgroundColor: getStatusColor(appointment.status),
                        color: getStatusTextColor(appointment.status),
                        fontWeight: 500,
                        borderRadius: '4px',
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No appointments available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className={classes.pagination}>
        <Pagination
          count={Math.ceil(appointments.length / rowsOnAssessment)}
          page={page}
          onChange={(e, value) => setPage(value)}
          color="primary"
          shape="rounded"
        />
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button variant="contained" color="primary" className={classes.submitButton}>
          Schedule New
        </Button>
      </Box>
    </div>
  );
};

export default PatientAppointmentsTab;
