import { useState } from "react"
import {
  Box,
  Paper,
  Typography,
  TextField,
  Grid,
  FormControl,
  FormControlLabel,
  Checkbox,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material"
import { Add, Delete, Print, Save } from "@mui/icons-material"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers"

const PsychiatristConsultationForm = () => {
  const today = new Date()
  const formattedDate = `${today.getDate().toString().padStart(2, "0")}/${(today.getMonth() + 1).toString().padStart(2, "0")}/${today.getFullYear()}`

  // State for patient information
  const [patientInfo, setPatientInfo] = useState({
    name: "",
    age: "",
    gender: "",
    education: "",
    maritalStatus: "",
    address: "",
    sessionType: "In-Person",
    sessionDate: today,
    duration: "20 mins",
  })

  // State for prescription medications
  const [medications, setMedications] = useState([
    { medicine: "", route: "Oral", dosage: "", frequency: "", duration: "", instructions: "" },
  ])

  // State for consultation location and doctor details
  const [consultationDetails, setConsultationDetails] = useState({
    location: "Delhi",
    email: "drgoravgupta@tulasihealthcare.com",
    registrationNumber: "",
  })

  // State for substance use checkboxes
  const [substanceUse, setSubstanceUse] = useState({
    alcohol: false,
    drugs: false,
    tobacco: false,
    others: false,
  })

  // Handle patient info changes
  const handlePatientInfoChange = (e) => {
    const { name, value } = e.target
    setPatientInfo({
      ...patientInfo,
      [name]: value,
    })
  }

  // Handle date change
  const handleDateChange = (date) => {
    setPatientInfo({
      ...patientInfo,
      sessionDate: date,
    })
  }

  // Handle substance use checkbox changes
  const handleSubstanceUseChange = (e) => {
    const { name, checked } = e.target
    setSubstanceUse({
      ...substanceUse,
      [name]: checked,
    })
  }

  // Handle medication changes
  const handleMedicationChange = (index, field, value) => {
    const updatedMedications = [...medications]
    updatedMedications[index][field] = value
    setMedications(updatedMedications)
  }

  // Add new medication row
  const addMedicationRow = () => {
    setMedications([
      ...medications,
      { medicine: "", route: "Oral", dosage: "", frequency: "", duration: "", instructions: "" },
    ])
  }

  // Remove medication row
  const removeMedicationRow = (index) => {
    if (medications.length > 1) {
      const updatedMedications = [...medications]
      updatedMedications.splice(index, 1)
      setMedications(updatedMedications)
    }
  }

  // Handle consultation details changes
  const handleConsultationDetailsChange = (e) => {
    const { name, value } = e.target
    setConsultationDetails({
      ...consultationDetails,
      [name]: value,
    })
  }

  // Handle print function
  const handlePrint = () => {
    window.print()
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        sx={{
          width: "210mm",
          minHeight: "297mm",
          margin: "0 auto",
          padding: "20px",
          backgroundColor: "#fff",
          boxShadow: "0 0 10px rgba(0,0,0,0.1)",
        }}
      >
        <Paper elevation={0} sx={{ padding: 3 }}>
          {/* Header */}
          <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: "bold", color: "#2c3e50" }}>
            Tulasi Healthcare
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
            <Button startIcon={<Save />} variant="contained" color="primary" sx={{ mr: 1 }}>
              Save
            </Button>
            <Button startIcon={<Print />} variant="outlined" color="primary" onClick={handlePrint}>
              Print
            </Button>
          </Box>

          {/* Patient Information Section */}
          <Paper elevation={1} sx={{ p: 2, mb: 3, backgroundColor: "#f8f9fa" }}>
            <Typography variant="h6" gutterBottom sx={{ color: "#2c3e50", borderBottom: "1px solid #e0e0e0", pb: 1 }}>
              Patient Information
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Patient Name"
                  name="name"
                  value={patientInfo.name}
                  onChange={handlePatientInfoChange}
                  margin="normal"
                  size="small"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label="Age"
                  name="age"
                  value={patientInfo.age}
                  onChange={handlePatientInfoChange}
                  margin="normal"
                  size="small"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth margin="normal" size="small">
                  <InputLabel>Gender</InputLabel>
                  <Select name="gender" value={patientInfo.gender} onChange={handlePatientInfoChange} label="Gender">
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Education"
                  name="education"
                  value={patientInfo.education}
                  onChange={handlePatientInfoChange}
                  margin="normal"
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Marital Status"
                  name="maritalStatus"
                  value={patientInfo.maritalStatus}
                  onChange={handlePatientInfoChange}
                  margin="normal"
                  size="small"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Address"
                  name="address"
                  value={patientInfo.address}
                  onChange={handlePatientInfoChange}
                  margin="normal"
                  size="small"
                />
              </Grid>

              <Grid item xs={4}>
                <DatePicker
                  label="Date of Session"
                  value={patientInfo.sessionDate}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} fullWidth margin="normal" size="small" />}
                  inputFormat="dd/MM/yyyy"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth margin="normal" size="small">
                  <InputLabel>Session Type</InputLabel>
                  <Select
                    name="sessionType"
                    value={patientInfo.sessionType}
                    onChange={handlePatientInfoChange}
                    label="Session Type"
                  >
                    <MenuItem value="In-Person">In-Person</MenuItem>
                    <MenuItem value="Online">Online</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth margin="normal" size="small">
                  <InputLabel>Duration</InputLabel>
                  <Select
                    name="duration"
                    value={patientInfo.duration}
                    onChange={handlePatientInfoChange}
                    label="Duration"
                  >
                    <MenuItem value="15 mins">15 mins</MenuItem>
                    <MenuItem value="20 mins">20 mins</MenuItem>
                    <MenuItem value="30 mins">30 mins</MenuItem>
                    <MenuItem value="45 mins">45 mins</MenuItem>
                    <MenuItem value="60 mins">60 mins</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>

          {/* Subjective Section */}
          <Paper elevation={1} sx={{ p: 2, mb: 3, backgroundColor: "#f8f9fa" }}>
            <Typography variant="h6" gutterBottom sx={{ color: "#2c3e50", borderBottom: "1px solid #e0e0e0", pb: 1 }}>
              1. Subjective
            </Typography>

            <TextField fullWidth label="Chief Complaint" multiline rows={2} margin="normal" size="small" />

            <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
              History of Present Illness:
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField fullWidth label="Onset" multiline rows={2} margin="normal" size="small" />
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth label="Duration" multiline rows={2} margin="normal" size="small" />
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth label="Progression" multiline rows={2} margin="normal" size="small" />
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth label="Other" multiline rows={2} margin="normal" size="small" />
              </Grid>
            </Grid>

            <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
              Past History:
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField fullWidth label="Medical" multiline rows={2} margin="normal" size="small" />
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth label="Psychiatric" multiline rows={2} margin="normal" size="small" />
              </Grid>
            </Grid>

            <TextField fullWidth label="Family History" multiline rows={2} margin="normal" size="small" />

            <TextField fullWidth label="Risk of Harm to self/others" multiline rows={2} margin="normal" size="small" />

            <TextField fullWidth label="Legal History" multiline rows={2} margin="normal" size="small" />

            <TextField
              fullWidth
              label="History of Drug Allergies / Serious adverse drug reaction"
              multiline
              rows={2}
              margin="normal"
              size="small"
            />

            <TextField fullWidth label="Last date of Menstrual Cycle" margin="normal" size="small" />

            <TextField fullWidth label="Systems" multiline rows={2} margin="normal" size="small" />

            <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
              Substance Use:
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox checked={substanceUse.alcohol} onChange={handleSubstanceUseChange} name="alcohol" />
                  }
                  label="Alcohol"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={<Checkbox checked={substanceUse.drugs} onChange={handleSubstanceUseChange} name="drugs" />}
                  label="Drugs"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox checked={substanceUse.tobacco} onChange={handleSubstanceUseChange} name="tobacco" />
                  }
                  label="Tobacco"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={<Checkbox checked={substanceUse.others} onChange={handleSubstanceUseChange} name="others" />}
                  label="Others"
                />
              </Grid>
            </Grid>

            <TextField fullWidth label="Medication Adherence" multiline rows={2} margin="normal" size="small" />
          </Paper>

          {/* Objective Section */}
          <Paper elevation={1} sx={{ p: 2, mb: 3, backgroundColor: "#f8f9fa" }}>
            <Typography variant="h6" gutterBottom sx={{ color: "#2c3e50", borderBottom: "1px solid #e0e0e0", pb: 1 }}>
              2. Objective
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Mental Status Examination (MSE):
            </Typography>

            <Typography variant="subtitle2" gutterBottom sx={{ mt: 1 }}>
              Appearance:
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField fullWidth label="Grooming" margin="normal" size="small" />
              </Grid>
              <Grid item xs={4}>
                <TextField fullWidth label="Attire" margin="normal" size="small" />
              </Grid>
              <Grid item xs={4}>
                <TextField fullWidth label="Hygiene" margin="normal" size="small" />
              </Grid>
            </Grid>

            <TextField fullWidth label="Behavior" margin="normal" size="small" />

            <TextField fullWidth label="Speech" margin="normal" size="small" />

            <TextField fullWidth label="Mood and Affect" margin="normal" size="small" />

            <Typography variant="subtitle2" gutterBottom sx={{ mt: 1 }}>
              Thoughts:
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField fullWidth label="Form" margin="normal" size="small" />
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth label="Stream" margin="normal" size="small" />
              </Grid>
            </Grid>

            <Typography variant="subtitle2" gutterBottom sx={{ mt: 1 }}>
              Thought Content:
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField fullWidth label="Delusions" margin="normal" size="small" />
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth label="Obsessions" margin="normal" size="small" />
              </Grid>
            </Grid>

            <TextField fullWidth label="Perception" margin="normal" size="small" />

            <Typography variant="subtitle2" gutterBottom sx={{ mt: 1 }}>
              Cognition:
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField fullWidth label="Orientation" margin="normal" size="small" />
              </Grid>
              <Grid item xs={4}>
                <TextField fullWidth label="Memory" margin="normal" size="small" />
              </Grid>
              <Grid item xs={4}>
                <TextField fullWidth label="Attention" margin="normal" size="small" />
              </Grid>
            </Grid>

            <TextField fullWidth label="Insight and Judgment" margin="normal" size="small" />

            <Typography variant="subtitle2" gutterBottom sx={{ mt: 1 }}>
              Vital Signs:
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField fullWidth label="BP" margin="normal" size="small" />
              </Grid>
              <Grid item xs={4}>
                <TextField fullWidth label="HR" margin="normal" size="small" />
              </Grid>
              <Grid item xs={4}>
                <TextField fullWidth label="Others" margin="normal" size="small" />
              </Grid>
            </Grid>
          </Paper>

          {/* Assessment Section */}
          <Paper elevation={1} sx={{ p: 2, mb: 3, backgroundColor: "#f8f9fa" }}>
            <Typography variant="h6" gutterBottom sx={{ color: "#2c3e50", borderBottom: "1px solid #e0e0e0", pb: 1 }}>
              3. Assessment
            </Typography>

            <TextField fullWidth label="Diagnoses" multiline rows={2} margin="normal" size="small" />

            <TextField fullWidth label="Clinical Impressions" multiline rows={2} margin="normal" size="small" />

            <TextField fullWidth label="Risk Assessment" multiline rows={2} margin="normal" size="small" />

            <TextField fullWidth label="Lab Investigations" multiline rows={2} margin="normal" size="small" />

            <TextField fullWidth label="Psychological Investigations" multiline rows={2} margin="normal" size="small" />
          </Paper>

          {/* Plan Section */}
          <Paper elevation={1} sx={{ p: 2, mb: 3, backgroundColor: "#f8f9fa" }}>
            <Typography variant="h6" gutterBottom sx={{ color: "#2c3e50", borderBottom: "1px solid #e0e0e0", pb: 1 }}>
              4. Plan
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Treatment Strategy:
            </Typography>

            <TextField fullWidth label="Medications" multiline rows={2} margin="normal" size="small" />

            <TextField fullWidth label="Therapeutic Interventions" multiline rows={2} margin="normal" size="small" />

            <TextField fullWidth label="Patient Education" multiline rows={2} margin="normal" size="small" />

            <TextField fullWidth label="Follow-Up" multiline rows={2} margin="normal" size="small" />

            <TextField fullWidth label="Referrals" multiline rows={2} margin="normal" size="small" />

            <TextField fullWidth label="Additional Notes" multiline rows={2} margin="normal" size="small" />

            <TextField fullWidth label="Clinician's Name" margin="normal" size="small" />
          </Paper>

          {/* E-Prescription Section */}
          <Paper elevation={1} sx={{ p: 2, mb: 3, backgroundColor: "#f8f9fa" }}>
            <Typography variant="h6" gutterBottom sx={{ color: "#2c3e50", borderBottom: "1px solid #e0e0e0", pb: 1 }}>
              E-Prescription
            </Typography>

            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={6}>
                <FormControl fullWidth margin="normal" size="small">
                  <InputLabel>Location</InputLabel>
                  <Select
                    name="location"
                    value={consultationDetails.location}
                    onChange={handleConsultationDetailsChange}
                    label="Location"
                  >
                    <MenuItem value="Delhi">Delhi Address</MenuItem>
                    <MenuItem value="Gurgaon">Gurgaon Address</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth margin="normal" size="small">
                  <InputLabel>Email</InputLabel>
                  <Select
                    name="email"
                    value={consultationDetails.email}
                    onChange={handleConsultationDetailsChange}
                    label="Email"
                  >
                    <MenuItem value="drgoravgupta@tulasihealthcare.com">drgoravgupta@tulasihealthcare.com</MenuItem>
                    <MenuItem value="rratna004@gmail.com">rratna004@gmail.com</MenuItem>
                    <MenuItem value="dranupsy@gmail.com">dranupsy@gmail.com</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Registration Number"
                  name="registrationNumber"
                  value={consultationDetails.registrationNumber}
                  onChange={handleConsultationDetailsChange}
                  margin="normal"
                  size="small"
                />
              </Grid>
            </Grid>

            <Typography variant="subtitle1" gutterBottom>
              Medication Details:
            </Typography>

            <TableContainer component={Paper} elevation={0} sx={{ mb: 2 }}>
              <Table size="small">
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                    <TableCell>Medicine</TableCell>
                    <TableCell>Route</TableCell>
                    <TableCell>Dosage</TableCell>
                    <TableCell>Frequency</TableCell>
                    <TableCell>Duration</TableCell>
                    <TableCell>Special Instructions</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {medications.map((med, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <TextField
                          fullWidth
                          size="small"
                          value={med.medicine}
                          onChange={(e) => handleMedicationChange(index, "medicine", e.target.value)}
                        />
                      </TableCell>
                      <TableCell>
                        <Select
                          size="small"
                          value={med.route}
                          onChange={(e) => handleMedicationChange(index, "route", e.target.value)}
                          fullWidth
                        >
                          <MenuItem value="Oral">Oral</MenuItem>
                          <MenuItem value="Injection">Injection</MenuItem>
                          <MenuItem value="Topical">Topical</MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          size="small"
                          value={med.dosage}
                          onChange={(e) => handleMedicationChange(index, "dosage", e.target.value)}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          size="small"
                          value={med.frequency}
                          onChange={(e) => handleMedicationChange(index, "frequency", e.target.value)}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          size="small"
                          value={med.duration}
                          onChange={(e) => handleMedicationChange(index, "duration", e.target.value)}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          size="small"
                          value={med.instructions}
                          onChange={(e) => handleMedicationChange(index, "instructions", e.target.value)}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton
                          size="small"
                          color="error"
                          onClick={() => removeMedicationRow(index)}
                          disabled={medications.length === 1}
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Button startIcon={<Add />} variant="outlined" color="primary" onClick={addMedicationRow} sx={{ mb: 3 }}>
              Add Medication
            </Button>

            <Typography variant="subtitle1" gutterBottom>
              Additional Instructions:
            </Typography>

            <TextField
              fullWidth
              label="Lifestyle or dietary recommendations"
              multiline
              rows={2}
              margin="normal"
              size="small"
            />

            <TextField
              fullWidth
              label="Information on potential side effects"
              multiline
              rows={2}
              margin="normal"
              size="small"
            />

            <TextField
              fullWidth
              label="Guidance on follow-up appointments"
              multiline
              rows={2}
              margin="normal"
              size="small"
            />

            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={6}>
                <DatePicker
                  label="Next Appointment"
                  value={today}
                  onChange={() => {}}
                  renderInput={(params) => <TextField {...params} fullWidth margin="normal" size="small" />}
                  inputFormat="dd/MM/yyyy"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth label="Digital Signature" margin="normal" size="small" />
              </Grid>
            </Grid>
          </Paper>
        </Paper>
      </Box>
    </LocalizationProvider>
  )
}

export default PsychiatristConsultationForm

