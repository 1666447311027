import { httpConstants } from "../constants";
import { httpService } from "../utility/httpService";
import { getHeaders } from "./baseFunctions";



const disorderModule = {
   addReportRemark,
   getReportRemark
};

export default disorderModule;

async function addReportRemark(requestData) {
    let url = process.env.REACT_APP_API_URI + "/api/private/report-remark"
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(),requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function getReportRemark(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/report-remark?remarkProviderId=${requestData.remarkProviderId}&remarkTypeId=${requestData.remarkTypeId}`
    return httpService(httpConstants.METHOD_TYPE.GET, getHeaders(),requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}
