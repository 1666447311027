"use client"

import { useEffect, useState } from "react"
import useStyles from "../doctorStyles"
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  Avatar,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Fade,
  Divider,
  Grid,
} from "@mui/material"
import { ArrowBack, CalendarMonth, Person } from "@mui/icons-material"
import { getStatusColor, getStatusTextColor } from "../utils/helpers"
import { useAuth } from "../../../../../AuthContext"
import { DoctorService } from "../../../../../services"
import moment from "moment"
import { showErrorMessage, showInfoMessage, showSuccessMessage } from "../../../../../managers/utility"
import PsychiatristConsultationForm from "../Psychiatrist/PsychiatristConsultation"
import PsychologistConsultationForm from "../Psychologist/PsychologistConsultation"

const AppointmentsTab = ({ appointments, page, setPage, setSelectedAppointment }) => {
  const classes = useStyles()
  const rowsPerPage = 10
  const { userDetails } = useAuth()
  const [appointmentsData, setAppointmentsData] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedAppointment, setSelectedAppointmentState] = useState(null)
  const [showConsultation, setShowConsultation] = useState(false)

  useEffect(() => {
    if (userDetails?._id) {
      getDocAppointments()
    }
  }, [userDetails])

  const getDocAppointments = async () => {
    try {
      const response = await DoctorService.getDoctorAppointments({ doctorId: userDetails?._id })
      setAppointmentsData(response || [])
    } catch (error) {
      console.error("Error fetching appointments:", error)
      setAppointmentsData([])
    }
  }

  const updateAppointmentStatus = async (appointmentId, status) => {
    try {
      console.log("id", appointmentId)
      const response = await DoctorService.updateAppointmentStatus({ appointmentId, status })
      console.log("response", response)
      if (response?.message === "Appointment status updated successfully") {
        getDocAppointments()
        showSuccessMessage("Status Updated Successfully")
      } else {
        showInfoMessage("Status Updated")
        getDocAppointments()
      }
    } catch (error) {
      console.error("Error updating appointment status:", error)
      showErrorMessage("Error Updating Status")
    }
  }

  const handleOpenDialog = (appointment) => {
    setSelectedAppointmentState(appointment)
    setOpenDialog(true)
  }

  const handleCloseDialog = () => setOpenDialog(false)

  const handleCloseConsultation = () => {
    setShowConsultation(false)
    // Update the appointment status after consultation is closed if needed
    if (selectedAppointment) {
      updateAppointmentStatus(selectedAppointment._id, "COMPLETED")
    }
  }

  const ManageAppointmentDialog = ({ open, onClose, appointment }) => {
    if (!appointment) return null

    const handleStatusUpdate = (newStatus) => {
      if (newStatus === "COMPLETED") {
        setShowConsultation(true) // Show the consultation component
        onClose() // Close the manage dialog
      } else {
        updateAppointmentStatus(appointment._id, newStatus)
        onClose()
      }
    }

    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" PaperProps={{ sx: { borderRadius: 4, p: 2 } }}>
        <DialogTitle sx={{ fontWeight: "bold", fontSize: "1.5rem" }}>Manage Appointment</DialogTitle>

        <DialogContent>
          <Typography variant="body1" sx={{ mb: 3 }}>
            Choose an action for this appointment:
          </Typography>
        </DialogContent>

        <DialogActions sx={{ display: "flex", flexDirection: "column", gap: 2, alignItems: "stretch" }}>
          <Button
            onClick={() => handleStatusUpdate("COMPLETED")}
            variant="contained"
            color="success"
            fullWidth
            sx={{ py: 1.5, fontWeight: "600" }}
          >
            ✅ Complete Appointment
          </Button>

          <Button
            onClick={() => handleStatusUpdate("CANCELED")}
            variant="contained"
            color="error"
            fullWidth
            sx={{ py: 1.5, fontWeight: "600" }}
          >
            ❌ Could Not Take
          </Button>

          <Button
            onClick={() => handleStatusUpdate("NO_SHOW")}
            variant="contained"
            fullWidth
            sx={{
              py: 1.5,
              fontWeight: "600",
              backgroundColor: "warning.main",
              "&:hover": { backgroundColor: "warning.dark" },
            }}
          >
            🚫 Patient No Show
          </Button>

          <Box sx={{ mt: 1 }}>
            <Button onClick={onClose} variant="outlined" fullWidth sx={{ py: 1.5, fontWeight: "600" }}>
              Close
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    )
  }

  // Render either the appointments table or the consultation form
  if (showConsultation && selectedAppointment) {
    return (
      <Fade in={showConsultation} timeout={400}>
        <Box sx={{ width: "100%", height: "100%" }}>
          {/* Professional Header */}
          <Box
            sx={{
              width: "100%",
              backgroundColor: "#f8fafc",
              borderBottom: "1px solid #e2e8f0",
              mb: 3,
              py: 2,
              px: { xs: 2, md: 3 },
              borderRadius: "8px 8px 0 0",
              boxShadow: "0 2px 4px rgba(0,0,0,0.04)",
            }}
          >
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Button
                  startIcon={<ArrowBack />}
                  onClick={handleCloseConsultation}
                  variant="outlined"
                  color="primary"
                  sx={{
                    borderRadius: "8px",
                    fontWeight: 500,
                    textTransform: "none",
                    px: 2,
                  }}
                >
                  Back to Appointments
                </Button>
              </Grid>
              <Grid item xs>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ mx: 2, display: { xs: "none", md: "block" } }}
                />
              </Grid>
              <Grid item xs={12} md="auto">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    flexWrap: "wrap",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Person sx={{ color: "primary.main", mr: 1 }} />
                    <Typography variant="subtitle1" fontWeight={600}>
                      {selectedAppointment.patientName}
                    </Typography>
                  </Box>
  
                  <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
  
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CalendarMonth sx={{ color: "primary.main", mr: 1 }} />
                    <Typography variant="body2" color="text.secondary">
                      {moment(selectedAppointment.startTime).format(
                        "DD MMM YYYY, h:mm a"
                      )}
                    </Typography>
                  </Box>
  
                  <Chip
                    label={selectedAppointment.status}
                    size="small"
                    sx={{
                      backgroundColor: getStatusColor(selectedAppointment.status),
                      color: getStatusTextColor(selectedAppointment.status),
                      fontWeight: 500,
                      borderRadius: "4px",
                      ml: { xs: 0, md: 2 },
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
  
          {/* Consultation Form Container */}
          <Box
            sx={{
              width: "100%",
              backgroundColor: "#fff",
              borderRadius: "8px",
              boxShadow:
                "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
              overflow: "hidden",
              transition: "all 0.3s ease",
              "&:hover": {
                boxShadow:
                  "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
              },
            }}
          >
            <Typography
              variant="h5"
              sx={{
                p: 3,
                borderBottom: "1px solid #e2e8f0",
                backgroundColor: "#f8fafc",
                fontWeight: 600,
                color: "#334155",
              }}
            >
              Consultation Form
            </Typography>
  
            <Box sx={{ p: { xs: 0, md: 2 } }}>
              {userDetails.specialization === "PSYCHOLOGIST" ? (
                <PsychologistConsultationForm appointment={selectedAppointment} />
              ) : userDetails.specialization === "PSYCHIATRIST" ? (
                <PsychiatristConsultationForm
                  appointment={selectedAppointment}
                  onClose={handleCloseConsultation}
                />
              ) : null}
            </Box>
          </Box>
        </Box>
      </Fade>
    );
  }
  

  return (
    <Fade in={!showConsultation} timeout={400}>
      <div>
        <Box
          sx={{
            mb: 3,
            p: 3,
            borderRadius: "8px",
            backgroundColor: "#f8fafc",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
          }}
        >
          <Typography variant="h5" fontWeight={600} color="primary.main">
            Welcome, Dr. {userDetails.name}
          </Typography>
          <Typography variant="h7" fontWeight={600}color="primary.main" sx={{ mt: 1 }}>
          {userDetails.specialization.charAt(0).toUpperCase() + userDetails.specialization.slice(1).toLowerCase()}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            Manage your appointments and patient consultations from your dashboard.
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
            px: 2,
          }}
        >
          <Typography variant="h6" fontWeight={600}>
            All Appointments
          </Typography>

          <Chip
            label={`${appointmentsData.length} Appointments`}
            color="primary"
            size="small"
            sx={{ fontWeight: 500 }}
          />
        </Box>

        <TableContainer
          component={Paper}
          sx={{
            boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#f1f5f9" }}>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                    Booking Id
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                    Patient Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                    Duration
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                    Time
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                    Booking Date
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                    Status
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                    Meeting Link
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {appointmentsData.length > 0 ? (
                appointmentsData.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((appointment, index) => (
                  <TableRow
                    key={index}
                    hover
                    sx={{
                      cursor: "pointer",
                      backgroundColor: index % 2 === 0 ? "#ffffff" : "#f8fafc",
                      "&:hover": {
                        backgroundColor: "#f1f5f9",
                      },
                    }}
                  >
                    <TableCell>
                      <Typography variant="body2" fontWeight={500}>
                        {appointment.bookingId}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Avatar
                          src={appointment.avatar}
                          alt={appointment.patientName}
                          sx={{
                            mr: 1.5,
                            width: 36,
                            height: 36,
                            border: "2px solid #e2e8f0",
                          }}
                        />
                        <Typography variant="body2" fontWeight={500}>
                          {appointment.patientName}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{appointment.duration}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{moment(appointment.startTime).format("DD-MMM-YY")}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{moment(appointment.bookedOn).format("DD-MMM-YY")}</Typography>
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={appointment.status}
                        size="small"
                        sx={{
                          backgroundColor: getStatusColor(appointment.status),
                          color: getStatusTextColor(appointment.status),
                          fontWeight: 500,
                          borderRadius: "4px",
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      {appointment.gmeetMeetingInfo?.joinLink ? (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          sx={{
                            textTransform: "none",
                            borderRadius: "6px",
                            fontWeight: 500,
                            boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
                            "&:hover": {
                              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                            },
                          }}
                          href={appointment.gmeetMeetingInfo.joinLink}
                          target="_blank"
                        >
                          Join Meeting
                        </Button>
                      ) : (
                        <Typography variant="body2" color="text.secondary">
                          No Link
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => handleOpenDialog(appointment)}
                        sx={{
                          borderRadius: "6px",
                          textTransform: "none",
                          fontWeight: 500,
                        }}
                      >
                        Manage Appointment
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8} align="center" sx={{ py: 4 }}>
                    <Typography variant="body1" color="text.secondary">
                      No appointments available
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Manage Appointment Dialog */}
        <ManageAppointmentDialog open={openDialog} onClose={handleCloseDialog} appointment={selectedAppointment} />
      </div>
    </Fade>
  )
}

export default AppointmentsTab

