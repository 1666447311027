import { useState } from "react"
import {
  Box,
  Paper,
  Typography,
  TextField,
  Grid,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormLabel,
  FormGroup,
} from "@mui/material"
import { Print, Save } from "@mui/icons-material"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers"

const PsychologistConsultationForm = () => {
  const today = new Date()
  const formattedDate = `${today.getDate().toString().padStart(2, "0")}/${(today.getMonth() + 1).toString().padStart(2, "0")}/${today.getFullYear()}`

  // State for patient information
  const [patientInfo, setPatientInfo] = useState({
    name: "",
    age: "",
    education: "",
    maritalStatus: "",
    address: "",
    sessionType: "In-Person",
    sessionDate: today,
    duration: "45 mins",
  })

  // State for presenting concerns
  const [presentingConcerns, setPresentingConcerns] = useState({
    chiefComplaints: "",
    selfReportedSymptoms: "",
  })

  // State for mental status examination
  const [mentalStatus, setMentalStatus] = useState({
    appearance: "Well-groomed",
    speech: "Normal",
    mood: "Euthymic",
    thoughts: "Coherent",
    insightJudgment: "Good",
    otherAppearance: "",
    otherMood: "",
    otherThoughts: "",
  })

  // State for assessment and diagnosis
  const [assessment, setAssessment] = useState({
    provisionalDiagnosis: "",
    clinicalImpressions: "",
    psychologicalAssessment: "",
  })

  // State for intervention and discussion
  const [intervention, setIntervention] = useState({
    topicsDiscussed: "",
    therapyTechniques: {
      cbt: false,
      psychoeducation: false,
      mindfulness: false,
      dbt: false,
      psychodynamic: false,
      other: false,
    },
    otherTechnique: "",
    homeworkRecommendations: "",
  })

  // State for plan and follow-up
  const [plan, setPlan] = useState({
    treatmentAdjustments: "",
    nextSessionDate: new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000), // Default to 1 week later
    additionalReferrals: "",
    clinicianName: "",
  })

  // Handle patient info changes
  const handlePatientInfoChange = (e) => {
    const { name, value } = e.target
    setPatientInfo({
      ...patientInfo,
      [name]: value,
    })
  }

  // Handle date change
  const handleDateChange = (date, field) => {
    if (field === "sessionDate") {
      setPatientInfo({
        ...patientInfo,
        sessionDate: date,
      })
    } else if (field === "nextSessionDate") {
      setPlan({
        ...plan,
        nextSessionDate: date,
      })
    }
  }

  // Handle presenting concerns changes
  const handlePresentingConcernsChange = (e) => {
    const { name, value } = e.target
    setPresentingConcerns({
      ...presentingConcerns,
      [name]: value,
    })
  }

  // Handle mental status changes
  const handleMentalStatusChange = (e) => {
    const { name, value } = e.target
    setMentalStatus({
      ...mentalStatus,
      [name]: value,
    })
  }

  // Handle assessment changes
  const handleAssessmentChange = (e) => {
    const { name, value } = e.target
    setAssessment({
      ...assessment,
      [name]: value,
    })
  }

  // Handle intervention changes
  const handleInterventionChange = (e) => {
    const { name, value } = e.target
    setIntervention({
      ...intervention,
      [name]: value,
    })
  }

  // Handle therapy techniques checkbox changes
  const handleTherapyTechniqueChange = (e) => {
    const { name, checked } = e.target
    setIntervention({
      ...intervention,
      therapyTechniques: {
        ...intervention.therapyTechniques,
        [name]: checked,
      },
    })
  }

  // Handle plan changes
  const handlePlanChange = (e) => {
    const { name, value } = e.target
    setPlan({
      ...plan,
      [name]: value,
    })
  }

  // Handle print function
  const handlePrint = () => {
    window.print()
  }

  // Handle save function
  const handleSave = () => {
    // Implement save functionality here
    console.log("Saving form data:", {
      patientInfo,
      presentingConcerns,
      mentalStatus,
      assessment,
      intervention,
      plan,
    })
    alert("Form data saved successfully!")
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        sx={{
          width: "210mm",
          minHeight: "297mm",
          margin: "0 auto",
          padding: "20px",
          backgroundColor: "#fff",
          boxShadow: "0 0 10px rgba(0,0,0,0.1)",
        }}
      >
        <Paper elevation={0} sx={{ padding: 3 }}>
          {/* Header */}
          <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: "bold", color: "#2c3e50" }}>
            Psychologist Consultation Form
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
            <Button startIcon={<Save />} variant="contained" color="primary" sx={{ mr: 1 }} onClick={handleSave}>
              Save
            </Button>
            <Button startIcon={<Print />} variant="outlined" color="primary" onClick={handlePrint}>
              Print
            </Button>
          </Box>

          {/* Patient Information Section */}
          <Paper elevation={1} sx={{ p: 2, mb: 3, backgroundColor: "#f8f9fa" }}>
            <Typography variant="h6" gutterBottom sx={{ color: "#2c3e50", borderBottom: "1px solid #e0e0e0", pb: 1 }}>
              Patient Information
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Patient Name"
                  name="name"
                  value={patientInfo.name}
                  onChange={handlePatientInfoChange}
                  margin="normal"
                  size="small"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label="Age"
                  name="age"
                  value={patientInfo.age}
                  onChange={handlePatientInfoChange}
                  margin="normal"
                  size="small"
                />
              </Grid>
              <Grid item xs={3}>
                <DatePicker
                  label="Date of Session"
                  value={patientInfo.sessionDate}
                  onChange={(date) => handleDateChange(date, "sessionDate")}
                  renderInput={(params) => <TextField {...params} fullWidth margin="normal" size="small" />}
                  inputFormat="dd/MM/yyyy"
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Education"
                  name="education"
                  value={patientInfo.education}
                  onChange={handlePatientInfoChange}
                  margin="normal"
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Marital Status"
                  name="maritalStatus"
                  value={patientInfo.maritalStatus}
                  onChange={handlePatientInfoChange}
                  margin="normal"
                  size="small"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Address"
                  name="address"
                  value={patientInfo.address}
                  onChange={handlePatientInfoChange}
                  margin="normal"
                  size="small"
                  multiline
                  rows={2}
                />
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth margin="normal" size="small">
                  <InputLabel>Session Type</InputLabel>
                  <Select
                    name="sessionType"
                    value={patientInfo.sessionType}
                    onChange={handlePatientInfoChange}
                    label="Session Type"
                  >
                    <MenuItem value="In-Person">In-Person</MenuItem>
                    <MenuItem value="Online">Online</MenuItem>
                    <MenuItem value="Phone">Phone</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth margin="normal" size="small">
                  <InputLabel>Duration</InputLabel>
                  <Select
                    name="duration"
                    value={patientInfo.duration}
                    onChange={handlePatientInfoChange}
                    label="Duration"
                  >
                    <MenuItem value="30 mins">30 mins</MenuItem>
                    <MenuItem value="45 mins">45 mins</MenuItem>
                    <MenuItem value="60 mins">60 mins</MenuItem>
                    <MenuItem value="90 mins">90 mins</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>

          {/* Presenting Concerns Section */}
          <Paper elevation={1} sx={{ p: 2, mb: 3, backgroundColor: "#f8f9fa" }}>
            <Typography variant="h6" gutterBottom sx={{ color: "#2c3e50", borderBottom: "1px solid #e0e0e0", pb: 1 }}>
              Presenting Concerns
            </Typography>

            <TextField
              fullWidth
              label="Chief complaints and reason for consultation"
              name="chiefComplaints"
              value={presentingConcerns.chiefComplaints}
              onChange={handlePresentingConcernsChange}
              margin="normal"
              size="small"
              multiline
              rows={3}
            />

            <TextField
              fullWidth
              label="Patient's self-reported symptoms/concerns"
              name="selfReportedSymptoms"
              value={presentingConcerns.selfReportedSymptoms}
              onChange={handlePresentingConcernsChange}
              margin="normal"
              size="small"
              multiline
              rows={3}
            />
          </Paper>

          {/* Mental Status Examination Section */}
          <Paper elevation={1} sx={{ p: 2, mb: 3, backgroundColor: "#f8f9fa" }}>
            <Typography variant="h6" gutterBottom sx={{ color: "#2c3e50", borderBottom: "1px solid #e0e0e0", pb: 1 }}>
              Mental Status Examination (MSE)
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl component="fieldset" margin="normal">
                  <FormLabel component="legend">Appearance & Behaviour</FormLabel>
                  <RadioGroup name="appearance" value={mentalStatus.appearance} onChange={handleMentalStatusChange}>
                    <FormControlLabel value="Well-groomed" control={<Radio />} label="Well-groomed" />
                    <FormControlLabel value="Dishevelled" control={<Radio />} label="Dishevelled" />
                    <FormControlLabel value="Cooperative" control={<Radio />} label="Cooperative" />
                    <FormControlLabel value="Restless" control={<Radio />} label="Restless" />
                    <FormControlLabel value="Other" control={<Radio />} label="Other" />
                  </RadioGroup>
                  {mentalStatus.appearance === "Other" && (
                    <TextField
                      fullWidth
                      label="Specify other appearance"
                      name="otherAppearance"
                      value={mentalStatus.otherAppearance}
                      onChange={handleMentalStatusChange}
                      margin="normal"
                      size="small"
                    />
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl component="fieldset" margin="normal">
                  <FormLabel component="legend">Speech</FormLabel>
                  <RadioGroup name="speech" value={mentalStatus.speech} onChange={handleMentalStatusChange}>
                    <FormControlLabel value="Normal" control={<Radio />} label="Normal" />
                    <FormControlLabel value="Pressured" control={<Radio />} label="Pressured" />
                    <FormControlLabel value="Slow" control={<Radio />} label="Slow" />
                    <FormControlLabel value="Monotonous" control={<Radio />} label="Monotonous" />
                    <FormControlLabel value="Loud" control={<Radio />} label="Loud" />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl component="fieldset" margin="normal">
                  <FormLabel component="legend">Mood & Affect</FormLabel>
                  <RadioGroup name="mood" value={mentalStatus.mood} onChange={handleMentalStatusChange}>
                    <FormControlLabel value="Euthymic" control={<Radio />} label="Euthymic" />
                    <FormControlLabel value="Anxious" control={<Radio />} label="Anxious" />
                    <FormControlLabel value="Depressed" control={<Radio />} label="Depressed" />
                    <FormControlLabel value="Congruent affect" control={<Radio />} label="Congruent affect" />
                    <FormControlLabel value="Incongruent affect" control={<Radio />} label="Incongruent affect" />
                    <FormControlLabel value="Other" control={<Radio />} label="Other" />
                  </RadioGroup>
                  {mentalStatus.mood === "Other" && (
                    <TextField
                      fullWidth
                      label="Specify other mood/affect"
                      name="otherMood"
                      value={mentalStatus.otherMood}
                      onChange={handleMentalStatusChange}
                      margin="normal"
                      size="small"
                    />
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl component="fieldset" margin="normal">
                  <FormLabel component="legend">Thoughts</FormLabel>
                  <RadioGroup name="thoughts" value={mentalStatus.thoughts} onChange={handleMentalStatusChange}>
                    <FormControlLabel value="Coherent" control={<Radio />} label="Coherent" />
                    <FormControlLabel value="Logical" control={<Radio />} label="Logical" />
                    <FormControlLabel value="Racing thoughts" control={<Radio />} label="Racing thoughts" />
                    <FormControlLabel value="Disorganized" control={<Radio />} label="Disorganized" />
                    <FormControlLabel value="Other" control={<Radio />} label="Other" />
                  </RadioGroup>
                  {mentalStatus.thoughts === "Other" && (
                    <TextField
                      fullWidth
                      label="Specify other thought pattern"
                      name="otherThoughts"
                      value={mentalStatus.otherThoughts}
                      onChange={handleMentalStatusChange}
                      margin="normal"
                      size="small"
                    />
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl component="fieldset" margin="normal">
                  <FormLabel component="legend">Insight & Judgment</FormLabel>
                  <RadioGroup
                    name="insightJudgment"
                    value={mentalStatus.insightJudgment}
                    onChange={handleMentalStatusChange}
                    row
                  >
                    <FormControlLabel value="Good" control={<Radio />} label="Good" />
                    <FormControlLabel value="Fair" control={<Radio />} label="Fair" />
                    <FormControlLabel value="Poor" control={<Radio />} label="Poor" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>

          {/* Assessment & Diagnosis Section */}
          <Paper elevation={1} sx={{ p: 2, mb: 3, backgroundColor: "#f8f9fa" }}>
            <Typography variant="h6" gutterBottom sx={{ color: "#2c3e50", borderBottom: "1px solid #e0e0e0", pb: 1 }}>
              Assessment & Diagnosis (if applicable)
            </Typography>

            <TextField
              fullWidth
              label="Provisional/Persistent diagnosis (if relevant)"
              name="provisionalDiagnosis"
              value={assessment.provisionalDiagnosis}
              onChange={handleAssessmentChange}
              margin="normal"
              size="small"
              multiline
              rows={3}
            />

            <TextField
              fullWidth
              label="Clinical impressions"
              name="clinicalImpressions"
              value={assessment.clinicalImpressions}
              onChange={handleAssessmentChange}
              margin="normal"
              size="small"
              multiline
              rows={3}
            />

            <TextField
              fullWidth
              label="Psychological Assessment"
              name="psychologicalAssessment"
              value={assessment.psychologicalAssessment}
              onChange={handleAssessmentChange}
              margin="normal"
              size="small"
              multiline
              rows={3}
            />
          </Paper>

          {/* Intervention & Discussion Section */}
          <Paper elevation={1} sx={{ p: 2, mb: 3, backgroundColor: "#f8f9fa" }}>
            <Typography variant="h6" gutterBottom sx={{ color: "#2c3e50", borderBottom: "1px solid #e0e0e0", pb: 1 }}>
              Intervention & Discussion
            </Typography>

            <TextField
              fullWidth
              label="Key topics discussed"
              name="topicsDiscussed"
              value={intervention.topicsDiscussed}
              onChange={handleInterventionChange}
              margin="normal"
              size="small"
              multiline
              rows={3}
            />

            <FormControl component="fieldset" margin="normal" fullWidth>
              <FormLabel component="legend">Therapy techniques used</FormLabel>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={intervention.therapyTechniques.cbt}
                      onChange={handleTherapyTechniqueChange}
                      name="cbt"
                    />
                  }
                  label="CBT"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={intervention.therapyTechniques.psychoeducation}
                      onChange={handleTherapyTechniqueChange}
                      name="psychoeducation"
                    />
                  }
                  label="Psychoeducation"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={intervention.therapyTechniques.mindfulness}
                      onChange={handleTherapyTechniqueChange}
                      name="mindfulness"
                    />
                  }
                  label="Mindfulness"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={intervention.therapyTechniques.dbt}
                      onChange={handleTherapyTechniqueChange}
                      name="dbt"
                    />
                  }
                  label="DBT"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={intervention.therapyTechniques.psychodynamic}
                      onChange={handleTherapyTechniqueChange}
                      name="psychodynamic"
                    />
                  }
                  label="Psychodynamic"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={intervention.therapyTechniques.other}
                      onChange={handleTherapyTechniqueChange}
                      name="other"
                    />
                  }
                  label="Other"
                />
              </FormGroup>
              {intervention.therapyTechniques.other && (
                <TextField
                  fullWidth
                  label="Specify other techniques"
                  name="otherTechnique"
                  value={intervention.otherTechnique}
                  onChange={handleInterventionChange}
                  margin="normal"
                  size="small"
                />
              )}
            </FormControl>

            <TextField
              fullWidth
              label="Homework or self-care recommendations"
              name="homeworkRecommendations"
              value={intervention.homeworkRecommendations}
              onChange={handleInterventionChange}
              margin="normal"
              size="small"
              multiline
              rows={3}
            />
          </Paper>

          {/* Plan & Follow-up Section */}
          <Paper elevation={1} sx={{ p: 2, mb: 3, backgroundColor: "#f8f9fa" }}>
            <Typography variant="h6" gutterBottom sx={{ color: "#2c3e50", borderBottom: "1px solid #e0e0e0", pb: 1 }}>
              Plan & Follow-up
            </Typography>

            <TextField
              fullWidth
              label="Treatment plan adjustments"
              name="treatmentAdjustments"
              value={plan.treatmentAdjustments}
              onChange={handlePlanChange}
              margin="normal"
              size="small"
              multiline
              rows={3}
            />

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <DatePicker
                  label="Next session date"
                  value={plan.nextSessionDate}
                  onChange={(date) => handleDateChange(date, "nextSessionDate")}
                  renderInput={(params) => <TextField {...params} fullWidth margin="normal" size="small" />}
                  inputFormat="dd/MM/yyyy"
                />
              </Grid>
            </Grid>

            <TextField
              fullWidth
              label="Additional referrals (if needed)"
              name="additionalReferrals"
              value={plan.additionalReferrals}
              onChange={handlePlanChange}
              margin="normal"
              size="small"
              multiline
              rows={2}
            />

            <TextField
              fullWidth
              label="Clinician's Name"
              name="clinicianName"
              value={plan.clinicianName}
              onChange={handlePlanChange}
              margin="normal"
              size="small"
            />
          </Paper>

          {/* Footer */}
          <Box sx={{ mt: 4, pt: 2, borderTop: "1px solid #e0e0e0", textAlign: "center" }}>
            <Typography variant="body2" color="text.secondary">
              This document is confidential and contains protected health information.
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Generated on: {formattedDate}
            </Typography>
          </Box>
        </Paper>
      </Box>
    </LocalizationProvider>
  )
}

export default PsychologistConsultationForm

