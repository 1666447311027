import { makeStyles } from "@mui/styles"

// Import the styles
const useStyles = makeStyles({
  patientPortal: {
    padding: "20px",
    backgroundColor: "white",
    minHeight: "100vh",
  },
  bottomNav: {
    marginBottom: "20px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    overflow: "hidden",
  },
  tableContainer: {
    marginBottom: "20px",
    borderRadius: "8px",
    boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
    overflow: "hidden",
  },
  clickableRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f0f7ff",
    },
  },
  patientDetails: {
    border: "1px solid #e0e0e0",
    padding: "20px",
    borderRadius: "12px",
    backgroundColor: "#fff",
    boxShadow: "0 2px 10px rgba(0,0,0,0.05)",
    marginBottom: "20px",
  },
  patientHeader: {
    display: "flex",
    alignItems: "center",
    marginBottom: "16px",
    padding: "10px 0",
    borderBottom: "1px solid #eaeaea",
  },
  patientIcon: {
    backgroundColor: "#e3f2fd",
    padding: "8px",
    borderRadius: "50%",
    marginRight: "12px",
  },
  textField: {
    marginTop: "10px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
    },
  },
  appointmentCard: {
    marginTop: "10px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.08)",
    transition: "transform 0.2s",
    "&:hover": {
      transform: "translateY(-2px)",
      boxShadow: "0 4px 8px rgba(0,0,0,0.12)",
    },
  },
  appointmentDate: {
    display: "flex",
    alignItems: "center",
    color: "#1976d2",
    fontWeight: "500",
  },
  appointmentIcon: {
    marginRight: "8px",
    color: "#1976d2",
  },
  assessment: {
    marginTop: "10px",
    padding: "16px",
    backgroundColor: "#e8f5e9",
    borderRadius: "8px",
    border: "1px solid #c8e6c9",
  },
  assessmentItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
  },
  scoreChip: {
    marginLeft: "8px",
    fontWeight: "bold",
  },
  backButton: {
    marginBottom: "10px",
    borderRadius: "8px",
    textTransform: "none",
    boxShadow: "none",
    padding: "8px 16px",
  },
  submitButton: {
    marginTop: "16px",
    borderRadius: "8px",
    textTransform: "none",
    padding: "8px 16px",
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
  tabContent: {
    minHeight: "300px",
  },
  journalField: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#fff",
      borderRadius: "8px",
    },
  },
  notesContainer: {
    backgroundColor: "#fff",
    padding: "16px",
    borderRadius: "8px",
    border: "1px solid #e0e0e0",
  },
  notesActions: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "16px",
  },
  mainTabs: {
    marginBottom: "20px",
    "& .MuiTabs-indicator": {
      backgroundColor: "#1976d2",
    },
    "& .MuiTab-root": {
      textTransform: "none",
      fontWeight: 500,
      fontSize: "16px",
    },
  },
  subTabs: {
    marginBottom: "20px",
    "& .MuiTabs-indicator": {
      backgroundColor: "#1976d2",
    },
    "& .MuiTab-root": {
      textTransform: "none",
      fontWeight: 500,
      fontSize: "14px",
    },
  },
  chartContainer: {
    height: 200,
    marginBottom: "20px",
  },
  journalCard: {
    marginBottom: "16px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.08)",
    transition: "all 0.2s ease",
    "&:hover": {
      boxShadow: "0 4px 8px rgba(0,0,0,0.12)",
    },
  },
  journalDate: {
    color: "#1976d2",
    fontWeight: 500,
    marginBottom: "8px",
  },
  journalContent: {
    color: "#333",
  },
  timeFilterContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },
  timeFilter: {
    minWidth: "150px",
    borderRadius: "8px",
    border: "1px solid #e0e0e0",
    padding: "8px 16px",
    backgroundColor: "#f5f5f5",
  },
  journalEntryContainer: {
    padding: "16px",
    backgroundColor: "#f9f9f9",
    borderRadius: "8px",
    marginBottom: "16px",
  },
  journalEntryDate: {
    color: "#1976d2",
    fontWeight: 500,
    marginBottom: "8px",
  },
  journalEntryContent: {
    color: "#333",
  },
  progressTitle: {
    fontWeight: 500,
    marginBottom: "16px",
    display: "flex",
    alignItems: "center",
  },
  progressIcon: {
    marginRight: "8px",
  },
  journalTitle: {
    fontWeight: 500,
    marginBottom: "16px",
    display: "flex",
    alignItems: "center",
  },
  journalIcon: {
    marginRight: "8px",
  },
  dropdownMenu: {
    position: "absolute",
    top: "100%",
    right: 0,
    zIndex: 1000,
    backgroundColor: "white",
    boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
    borderRadius: "8px",
    padding: "8px 0",
    minWidth: "150px",
  },
  dropdownItem: {
    padding: "8px 16px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
  journalMetrics: {
    display: "flex",
    gap: "16px",
    marginBottom: "16px",
  },
  metricCard: {
    flex: 1,
    padding: "16px",
    borderRadius: "8px",
    backgroundColor: "#f5f9ff",
    border: "1px solid #e0e9fa",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  metricValue: {
    fontSize: "24px",
    fontWeight: "bold",
    marginTop: "8px",
  },
  metricLabel: {
    fontSize: "14px",
    color: "#666",
    marginTop: "4px",
  },
  journalHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },
  journalTabs: {
    marginBottom: "20px",
  },
})

export default useStyles;