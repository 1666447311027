import { httpConstants } from "../constants";
import { httpService } from "../utility/httpService";
import { getHeaders } from "./baseFunctions";

const authModule = {
    registerNewUser,
    signUpUser,
    loginUser,
    loginCorporateEmployee,
    signInUser,
    getUserSubscriptions,
    signInAdmin,
    getUserById,
    // forgote password
    GetOtp,
    GetConfirmOtp,
    GetResetPassword,
    //VerifyUser
    GetVerifyUser,
    getUserJournalData,
    //get User Details By Id
    getUserDetailsByEmail
};

export default authModule;



async function registerNewUser(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/user/register`
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(), requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function loginUser(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/user/userLogin`
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(), requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function loginCorporateEmployee(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/user/login`
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(), requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

// signup form
async function signUpUser(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/user/signup`
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(), requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

// signin user
async function signInUser(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/user/signInUser`
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(), requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function signInAdmin(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/user/admin-login`
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(), requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function getUserSubscriptions(userId) {
    let url = process.env.REACT_APP_API_URI + `/api/user/subscriptions/${userId}`
    return httpService(httpConstants.METHOD_TYPE.GET, getHeaders(), {}, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function getUserById(userId, type) {
    let url = process.env.REACT_APP_API_URI + `/api/user/get-user-by-id?userId=${userId}&type=${type}`
    return httpService(httpConstants.METHOD_TYPE.GET, getHeaders(), {}, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function getUserJournalData(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/get-user-status/`
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(), requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

//forgot-password
async function GetOtp(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/user/forgot-password`
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(), requestData,url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

// confirm-otp
async function GetConfirmOtp(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/user/confirm-otp`
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(), requestData,url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

// reset-password
async function GetResetPassword(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/user/reset-password`
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(), requestData,url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

// verified User
async function GetVerifyUser(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/user/verify`
    return httpService(httpConstants.METHOD_TYPE.PUT, getHeaders(), requestData,url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}


async function getUserDetailsByEmail(req) {
    let url = process.env.REACT_APP_API_URI + `/api/user/get-user-all-details/${req.email}`
    return httpService(httpConstants.METHOD_TYPE.GET, getHeaders(),{},url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}