import useStyles from "../doctorStyles"
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
} from "@mui/material";
import { Person as PersonIcon } from "@mui/icons-material";

const PatientDirectory = ({ patients, page, setPage, setSelectedPatient }) => {
  const classes = useStyles();
  const rowsPerPage = 10;

  return (
    <>
      <Typography variant="h5" style={{ marginBottom: "20px", fontWeight: "500" }}>
        Patient Directory
      </Typography>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: "#f5f5f5" }}>
              <TableCell>
                <Typography variant="subtitle2">Name</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">Email</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">Mobile</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {patients.length > 0 ? (
              patients.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((patient, index) => (
                <TableRow
                  key={index}
                  hover
                  onClick={() => setSelectedPatient(patient)}
                  className={classes.clickableRow}
                >
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <PersonIcon style={{ marginRight: "8px", color: "#757575" }} />
                      {patient.name || "N/A"}
                    </Box>
                  </TableCell>
                  <TableCell>{patient.email || "N/A"}</TableCell>
                  <TableCell>{patient.mobile || "N/A"}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No patients available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className={classes.pagination}>
        <Pagination
          count={Math.ceil((patients.length || 1) / rowsPerPage)}
          page={page}
          onChange={(e, value) => setPage(value)}
          color="primary"
          shape="rounded"
        />
      </Box>
    </>
  );
};

export default PatientDirectory;