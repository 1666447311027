import useStyles from "../doctorStyles"
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  Card,
  Pagination,
} from "@mui/material"
import { Person as PersonIcon, Assessment as AssessmentIcon } from "@mui/icons-material"

const AssessmentTab = ({ answerSessionCount, page, setPage }) => {
  const classes = useStyles()
  const rowsOnAssessment = 5

  return (
    <div>
      <Typography variant="h6" gutterBottom display="flex" alignItems="center">
        <AssessmentIcon style={{ marginRight: "8px" }} />
        Assessment Results
      </Typography>
      <Card className={classes.assessment}>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: "#f5f5f5" }}>
                <TableCell>
                  <Typography variant="subtitle2">Session Type</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">Updated At</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {answerSessionCount.length > 0 ? (
                answerSessionCount
                  .slice((page - 1) * rowsOnAssessment, page * rowsOnAssessment)
                  .map((session, index) => (
                    <TableRow key={index} hover className={classes.clickableRow}>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          <PersonIcon style={{ marginRight: "8px", color: "#757575" }} />
                          {session.sessionType || "N/A"}
                        </Box>
                      </TableCell>
                      <TableCell>{session.updatedAt ? new Date(session.updatedAt).toLocaleString() : "N/A"}</TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    No sessions available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box className={classes.pagination}>
          <Pagination
            count={Math.ceil((answerSessionCount.length || 1) / rowsOnAssessment)}
            page={page}
            onChange={(e, value) => setPage(value)}
            color="primary"
            shape="rounded"
          />
        </Box>
      </Card>
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button variant="outlined" color="primary" style={{ marginRight: "8px" }}>
          View History
        </Button>
        <Button variant="contained" color="primary">
          New Assessment
        </Button>
      </Box>
    </div>
  )
}

export default AssessmentTab