import { useEffect, useState } from 'react';
import useStyles from './doctorStyles';
import { Tabs, Tab } from '@mui/material';
import { Event as EventIcon, Person as PersonIcon } from '@mui/icons-material';
import AppointmentsTab from './tabs/AppointmentsTab';
import PatientDirectory from './tabs/PatientDirectory';
import PatientDetails from './tabs/PatientDetails';
import { dummyPatients, appointments } from './data/mockData';
import { EntityService } from '../../../../services';
import { useAuth } from '../../../../AuthContext';
import { showErrorMessage } from '../../../../managers/utility';
import answerSessionServiceModule from '../../../../services/answerSession';

const DoctorPortal = () => {
  const classes = useStyles();
  const [mainTab, setMainTab] = useState(0);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [apiData, setApiData] = useState([]);
  const [page, setPage] = useState(1);
  const [answerSessionCount, setAnswerSessionCount] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const { userDetails } = useAuth();

  useEffect(() => {
    getAnswerSessions();
  }, [selectedPatient]);

  const getAnswerSessions = async () => {
    try {
      if (selectedPatient && selectedPatient?._id) {
        let answerSession = await answerSessionServiceModule.getAllSessions(0, 0, selectedPatient?._id);
        setAnswerSessionCount(answerSession?.data);
      }
    } catch (error) {
      console.error('Error fetching answer sessions:', error);
    }
  };

  const getEntityPatients = async () => {
    if (userDetails && userDetails?.entityId) {
      const patients = await EntityService.getEntityPatients({ entityId: userDetails?.entityId });
      console.log(patients, 'patients');
      setApiData(patients);
    } else {
      showErrorMessage('Error in finding your hospital');
    }
  };




  useState(() => {
    getEntityPatients();
  });

  return (
    <div className={classes.patientPortal}>
      <Tabs
        value={mainTab}
        onChange={(e, newValue) => {
          setMainTab(newValue);
          if (newValue === 0) setSelectedPatient(null);
        }}
        className={classes.mainTabs}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        sx={{
          '& .MuiTabs-indicator': {
            display: 'none', // Hide default bottom indicator
          },
        }}
      >
        <Tab label="My Appointments" icon={<EventIcon />} iconPosition="start" />
        <Tab label="Patient Information" icon={<PersonIcon />} iconPosition="start" />
        </Tabs>

      {mainTab === 0 && (
        <AppointmentsTab appointments={appointments} page={page} setPage={setPage} setSelectedAppointment={setSelectedAppointment} />
      )}

      {mainTab === 1 && !selectedPatient && (
        <PatientDirectory patients={apiData} page={page} setPage={setPage} setSelectedPatient={setSelectedPatient} />
      )}

      {mainTab === 1 && selectedPatient && (
        <PatientDetails
          patient={selectedPatient}
          setSelectedPatient={setSelectedPatient}
          appointments={appointments}
          answerSessionCount={answerSessionCount}
          page={page}
          setPage={setPage}
          setSelectedAppointment={setSelectedAppointment}
        />
      )}
    </div>
  );
};

export default DoctorPortal;
